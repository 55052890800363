import React from "react";
import { images } from "../../../constants/ImageImports";

const downloadDummyPDF = (e, docPath) => {
  const link = document.createElement("a");
  link.href = docPath;
  link.target = "_blank";
  link.download = docPath;

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
};

export const DownloadButton = ({ item }) => {
  return (
    <button
      className="download_btn"
      onClick={(e) => downloadDummyPDF(e, item)}
    >
      <span>Download</span>
      <span>
        <img src={images?.downloadIcon} alt="img" />
      </span>
    </button>
  );
};
