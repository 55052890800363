import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { useSelector } from "react-redux";

const lastFourQuator = ["1st", "2nd", "3rd", "4th"];

const PieChart = ({ data, options }) => {

  const [pieValue, setPieValue] = useState([]);
  const loading = useSelector(state => state?.loaderSlice?.loader)
  const getValue = (temp) => {

    setPieValue(
      temp?.map((value, i, arr) => {
        return [lastFourQuator?.[i], value];
      })
    );
  };


  const modifiedOptions = {
    legend: {
      position: "start",
      textStyle: {
        color: "#848A9C",
        fontSize: 14,
      },
    },
    colors: ["#04BFDA", "#FB67CA", "#FFA84A", "#E75A7C"],

    ...options,
  };

  useEffect(() => {
    getValue(data?.[0]);

  }, [data]);



  console.log('pieValue==>', pieValue)

  return (
    <>
      {pieValue?.length > 0 && (
        <Chart
          options={modifiedOptions}
          chartType="PieChart"
          data={[["Task", "Hours per Day"], ...pieValue]}
          width={"100%"}
        />
      )}
      {pieValue?.length === 0 && !loading && <div style={{
        backgroundColor: "white",
        padding: "20px",
        borderRadius: "5px",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        minHeight: '23vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 700,
        color: 'gray'
      }}>
        No Data Found
      </div>
      }
    </>
  );
};
export default PieChart;
