import React, { useState, useEffect } from "react";
import { images } from "../../constants/ImageImports";
import { Editor } from "../../components/common/editor/Editor";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getToast } from "../../utils";
import { TOAST_MESSAGE } from "../../constants/constants";
import { toggleLoader } from "../../redux/slices/loaderSlice";
import { postApi } from "../../api/api.client";
import { APIS } from "../../api/api.constant";

const TermAndCondition = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [content, setContent] = useState("");

  const submitHandle = () => {
    if (content === "" || content === "<p><br></p>") {
      getToast("error", TOAST_MESSAGE.EMPTY_TEXT);
    } else {
      dispatch(toggleLoader({ loader: true }));
      postApi(APIS.CREATE_STATIC_CONTENT, {
        page_name: "terms",
        content: content,
      })
        .then((res) => {
          getToast("success", TOAST_MESSAGE.UPDATED);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          dispatch(toggleLoader({ loader: false }));
        });
    }
  };

  useEffect(() => {
    dispatch(toggleLoader({ loader: true }));
    postApi(APIS.GET_STATIC_CONTENT, {
      page_name: "terms",
    })
      .then((res) => {
        setContent(res?.payload?.data?.content);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(toggleLoader({ loader: false }));
      });
  }, []);

  return (
    <div className="privacy-page sec-padding min-vh">
      <div className="cstm_Ctnr">
        <div className="privacy_layout">
          <div className="privacy_layout_wpr mb-30">
            <div className="privacy_heading mb-0">
              <div className="privacy_heading_icon">
                <img src={images?.termandcondition} alt="img" />
              </div>
              <h4>Terms & Conditions</h4>
            </div>

            <div className="privacy_view_icon">
              <Link to="/view-term-and-conditions">
                <img src={images?.editorViewIcon} alt="img" />
              </Link>
            </div>
          </div>
          <Editor setContent={setContent} content={content} />
          <div className="text-right mt-30">
            <button
              className="btn_reset pink_btn ml-auto"
              onClick={submitHandle}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermAndCondition;
