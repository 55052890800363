import { createSlice } from "@reduxjs/toolkit";

const initialModalState = {
  value: {},
  isModal: false,
  type: "",
  actionHandler: () => {},
};

const modalSlice = createSlice({
  name: "modal",
  initialState: initialModalState,
  reducers: {
    showModal(state, action) {
      state.isModal = true;
      state.value = action?.payload?.value ? action?.payload?.value : {};
      state.type = action?.payload?.type;
      state.actionHandler = action?.payload?.actionHandler;
    },
    hideModal(state) {
      state.value = {};
      state.isModal = false;
      state.type = "";
      state.actionHandler = () => {};
    },
  },
});

export default modalSlice;
export const { showModal, hideModal } = modalSlice.actions;
