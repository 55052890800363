import * as Yup from "yup";

const FIELDS = {
  studentName: {
    label: "Name *",
    name: "name",
    placeholder: "Enter name",
    type: "text",
    component: "input",
    style: { minWidth: "590px", maxWidth: "590px" },
  },
  phone: {
    label: "Phone",
    name: "phone",
    placeholder: "Enter phone number",
    type: "tel",
    component: "input",
    style: { minWidth: "590px", maxWidth: "590px" },
  },
  email: {
    isDisabled: true,
    label: "Email *",
    name: "email",
    placeholder: "Enter email",
    type: "email",
    component: "input",
    style: { minWidth: "590px", maxWidth: "590px" },
  },
  address: {
    label: "Address",
    name: "address",
    placeholder: "Enter address",
    type: "text",
    component: "textarea",
    style: { width: "1220px", height: "132px" },
  },
  age: {
    label: "Age",
    name: "age",
    placeholder: "Enter age",
    type: "number",
    component: "input",
    style: { minWidth: "590px", maxWidth: "590px" },
  },
  class: {
    label: "Class Name *",
    name: "class_id",
    placeholder: "Select class",
    component: "select",
    classOptions: true,
    style: {
      minWidth: "590px",
      maxWidth: "590px",
    },
  },
  course: {
    label: "Course *",
    name: "course_name",
    placeholder: "Enter course",
    type: "text",
    component: "input",
    style: { minWidth: "590px", maxWidth: "590px" },
  },

  pronoun: {
    label: "Pronouns *",
    name: "gender",
    placeholder: "Select pronouns",
    component: "select",
    options: [
      { label: "He/Him", value: "He/Him" },
      { label: "She/Her", value: "She/Her" },
      { label: "They/Them", value: "They/Them" },
      { label: "Other", value: "Other" },
    ],
    style: { minWidth: "590px", maxWidth: "590px" },
  },

  teacherName: {
    label: "Name *",
    name: "name",
    placeholder: "Enter name",
    type: "text",
    component: "input",
    style: { minWidth: "590px", maxWidth: "590px" },
  },
  smallName: {
    label: "Name *",
    name: "name",
    placeholder: "Enter name",
    type: "text",
    component: "input",
    style: { minWidth: "451px", maxWidth: "451px" },
  },
  smallPhone: {
    label: "Phone",
    name: "phone",
    placeholder: "Enter phone number",
    type: "tel",
    component: "input",
    style: { minWidth: "451px", maxWidth: "451px" },
  },
  smallEmail: {
    isDisabled: true,
    label: "Email *",
    name: "email",
    placeholder: "Enter email",
    type: "email",
    component: "input",
    style: { minWidth: "451px", maxWidth: "451px" },
  },
  smallAddress: {
    label: "Address",
    name: "address",
    placeholder: "Enter address",
    type: "text",
    component: "textarea",
    style: {
      minWidth: "451px",
      maxWidth: "451px",
      borderRadius: "24px",
      border: "1px solid #197BBD",
      background: "#FDFDFD",
      padding: "14px 20px",
      width: "100%",
      fontSize: "14px",
      color: "#000",
    },
  },
  adminEmail: {
    isDisabled: true,
    label: "Email *",
    name: "email",
    placeholder: "Enter email",
    type: "email",
    component: "input",
    style: { minWidth: "451px", maxWidth: "451px" },
  },
};

export const Schema = {
  name: Yup.string()
    .min(3, "Username should be between (2-40) characters")
    .max(39, "Username should be between (2-40) characters")
    .matches(/^[A-Za-z\s]+$/, "Only alphabets and spaces are allowed")
    .required("Required*"),

  email: Yup.string()
    .email("Email should be valid")
    .required("Required*")
    .matches(
      /^[^\s@]+@[^\s@]+\.(com|ca|io|uk|aus|in)$/,
      "Invalid email format"
    ),
  phone: Yup.string()
    .length(10, "Phone no. should be of 10 digits")
    .matches(/^[0-9]+$/, "Phone number must contain only digits"),

  address: Yup.string().max(150, "Address must not exceed 150 characters."),
  class_id: Yup.object().required("Required*"),
  course_name: Yup.string()
    .required("Required*")
    .max(30, "Course name must not exceed 30 characters"),

  pronoun: Yup.object().required("Required*"),
};

export const STUDENT_FORM = {
  fields: [
    FIELDS?.studentName,
    FIELDS?.email,
    FIELDS.address,
    FIELDS.class,
    FIELDS.course,
    FIELDS.phone,
    FIELDS.pronoun,
    FIELDS.age,
  ],
  schema: Yup.object().shape({
    name: Schema?.name,
    email: Schema.email,
    address: Schema.address,
    class_id: Schema.class_id,
    course_name: Schema.course_name,
    phone: Schema?.phone,
    gender: Schema?.pronoun,
    age: Schema.age,
  }),
  intialvalues: {
    name: "",
    email: "",
    phone: "",
    gender: "",
    age: null,
    address: "",
    profile_image: null,
    course_name: "",
    class_id: "",
    background: null,
  },
};

export const TEACHER_FORM = {
  fields: [
    FIELDS?.teacherName,
    FIELDS?.email,
    FIELDS.address,
    FIELDS.phone,
    FIELDS.pronoun,
    FIELDS.age,
  ],
  schema: Yup.object().shape({
    name: Schema?.name,
    email: Schema.email,
    address: Schema.address,
    phone: Schema?.phone,
    gender: Schema?.pronoun,
    age: Schema.age,
  }),
  intialvalues: {
    name: "",
    email: "",
    phone: "",
    gender: "",
    age: null,
    address: "",
    profile_image: null,
    background: null,
  },
};

export const ADMIN_UPDATE_FORM = {
  FIELDS: [FIELDS.smallName, FIELDS.smallPhone, FIELDS.smallEmail],
  SCHEMA: Yup.object().shape({
    name: Schema?.name,
    phone: Schema?.phone,
  }),
  INITIAL_VALUES: {
    name: "",
    phone: "",
    email: "",
    profile_image: "",
    address: "",
    age: "",
    gender: "",
  },
};

export const FORGOT_PASSWORD = {
  FIELDS: [FIELDS.smallEmail],
  SCHEMA: Yup.object().shape({
    email: Schema?.email,
  }),
  INITIAL_VALUES: {
    email: "",
  },
};

export const ADMIN_INFO = {
  FIELDS: [FIELDS.smallPhone, FIELDS.adminEmail, FIELDS.smallAddress],
  SCHEMA: Yup.object().shape({
    phone: Schema?.phone,
    email: Schema.email,
    address: Schema.address,
  }),
  INITIAL_VALUES: {
    phone: "",
    email: "",
    address: "",
  },
};
