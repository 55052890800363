import React, { useEffect } from "react";
import { WithTodoListHoc } from "../../hoc";

const ShowTab = (props) => {
  useEffect(() => {
    props.setApiType("all");
  });
  return <></>;
};
export default WithTodoListHoc(ShowTab);
