import React, { useEffect, useState } from "react";
import { images } from "../../constants/ImageImports";
import { postApi } from "./../../api/api.client";
import { useDispatch } from "react-redux";
import { toggleLoader } from "../../redux/slices/loaderSlice";
import { APIS } from "../../api/api.constant";
import { NoStringFound } from "../../components/common";

const Privacy = () => {
  const dispatch = useDispatch();
  const [content, setContent] = useState("");
  useEffect(() => {
    dispatch(toggleLoader({ loader: true }));
    postApi(APIS.GET_STATIC_CONTENT, {
      page_name: "privacy-policy",
    })
      .then((res) => {
        setContent(res?.payload?.data?.content);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(toggleLoader({ loader: false }));
      });
  }, []);

  return (
    <div className="privacy-page sec-padding min-vh">
      <div className="cstm_Ctnr">
        <div className="privacy_layout">
          <div className="privacy_heading">
            <div className="privacy_heading_icon">
              <img src={images?.privacy} alt="img" />
            </div>
            <h4>Privacy Policy</h4>
          </div>
          <div
            lassName="privacy_content"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
          {content === "" && <NoStringFound data={content} />}
        </div>
      </div>
    </div>
  );
};

export default Privacy;
