import { toast } from "react-toastify";
export const getToast = (type, message) => {
  switch (type) {
    case "success":
      return toast.success(message);
    case "error":
      return toast.error(message);
    default:
      console.log("handle for this");
      return <></>;
  }
};

export const isValid = (value) => {
  if (value === null || value === "" || value === undefined || value === "null") {
    return "N/A";
  }
  return value;
};

export const getFormattedDate = (dateStr) => {
  const dateObj = new Date(dateStr);
  if (isValid(dateStr)) {
    return `${dateObj.getDate()}-${
      dateObj.getMonth() + 1
    }-${dateObj.getFullYear()}`;
  }
  return "N/A";
};
