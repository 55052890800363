import React from "react";
import "./modal.css";
import { useSelector } from "react-redux";
import { MODAL_TYPE } from "../../redux/constant/reduxConstant";
import { EditClass } from "./EditClass";
import { Logout } from "./Logout";
import { VoiceModal } from "./VoiceModal";
import { AddStudent } from "./AddStudent";
import { Delete } from "./Delete";
import { StatusChanged } from "./StatusChanged";

export const Modal = () => {
  const { type, isModal, actionHandler, value } = useSelector(
    (state) => state?.modalSlice
  );

  const modalType = () => {
    switch (type) {
      case MODAL_TYPE.EDIT_CLASS:
        return <EditClass actionHandler={actionHandler} value={value} />;
      case MODAL_TYPE.ADD_STUDENT:
        return <AddStudent actionHandler={actionHandler} />;
      case MODAL_TYPE.LOGOUT:
        return <Logout actionHandler={actionHandler} />;
      case MODAL_TYPE.VOICE_TONE:
        return <VoiceModal actionHandler={actionHandler} />;
      case MODAL_TYPE.DELETE:
        return <Delete actionHandler={actionHandler} />;
      case MODAL_TYPE.STATUS:
        return <StatusChanged actionHandler={actionHandler} />;
      default:
        return null;
    }
  };

  return <>{isModal ? <div className="cstm_mdl">{modalType()}</div> : ""}</>;
};
