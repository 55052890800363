import React, { useEffect, useState } from "react";
import { Tabbing } from "../../components/common/tabbing/Tabbing";
import {
  CompletedTask,
  ShowTab,
  UpcomingTask,
  PresentTask,
} from "../../features/todoListTabContent";
import { images } from ".././../constants/ImageImports";
import { postApi } from "../../api/api.client";
import { APIS } from "../../api/api.constant";
import { useDispatch } from "react-redux";
import { toggleLoader } from "../../redux/slices/loaderSlice";

const TodoList = () => {
  const dispatch = useDispatch();
  const [count, setCount] = useState({
    all: "",
    upcoming: "",
    present: "",
    complete: "",
  });

  useEffect(() => {
    dispatch(toggleLoader({ loader: true }));
    const apiRequests = [
      postApi(APIS.TO_DO_LIST, {
        todo_type: "all",
        page: 1,
        pageSize: 11,
      }),
      postApi(APIS.TO_DO_LIST, {
        todo_type: "upcoming",
        page: 1,
        pageSize: 11,
      }),
      postApi(APIS.TO_DO_LIST, {
        todo_type: "current",
        page: 1,
        pageSize: 11,
      }),
      postApi(APIS.TO_DO_LIST, {
        todo_type: "past",
        page: 1,
        pageSize: 11,
      }),
    ];
    Promise.all(apiRequests)
      .then(([allCount, upcommingCount, currentCount, pastCount]) => {
        setCount({
          all: allCount?.payload?.data?.length,
          upcoming: upcommingCount?.payload?.data?.length,
          present: currentCount?.payload?.data?.length,
          complete: pastCount?.payload?.data?.length,
        });
      })
      .catch(() => {})
      .finally(() => {
        dispatch(toggleLoader({ loader: false }));
      });
  }, []);

  const TodoListTabbing = [
    {
      title: "Show All",
      content: <ShowTab />,
      icon: images?.all,
      task: count?.all,
    },
    {
      title: "Upcoming",
      content: <UpcomingTask />,
      icon: images?.present,
      task: count?.upcoming,
    },
    {
      title: "Present To-do",
      content: <PresentTask />,
      icon: images?.upcoming,
      task: count?.present,
    },
    {
      title: "Past To-do List",
      content: <CompletedTask />,
      icon: images?.completed,
      task: count?.complete,
    },
  ];

  return (
    <div className="todo-list-page sec-padding min-vh">
      <div className="cstm_Ctnr">
        <div className="tab_btn_full mt-40">
          <Tabbing tabs={TodoListTabbing} />
        </div>
      </div>
    </div>
  );
};

export default TodoList;
