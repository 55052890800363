import React from "react";
import "./components/assets/fonts/stylesheet.css";
import "./components/assets/css/style.css";
import Router from "./layout/Router";
import { useSelector } from "react-redux";
import { Loader } from "./components/common";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const App = () => {
  const loading = useSelector((state) => state?.loaderSlice?.loader);

  return (
    <>
      {loading && <Loader />}
      <Router />
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default App;
