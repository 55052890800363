import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Sidebar from "../components/common/sidebar/Sidebar";
import Header from "../components/common/header/Header";
import { useSelector } from "react-redux";

function Maincontainer() {
  const token = useSelector((state) => state?.authSlice?.token);

  return (
    <>
      {token ? (
        <div className="main_container">
          <Sidebar />
          <div className="ctnt_wpr">
            <Header />
            <Outlet />
          </div>
        </div>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
}
export default Maincontainer;
