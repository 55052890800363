import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { ImageUploader, MixComponents } from "../../../components/common";
import { TEACHER_FORM } from "../../../constants/formik";
import { postApi } from "../../../api/api.client";
import { APIS } from "../../../api/api.constant";
import { images } from "../../../constants/ImageImports";
import { toggleLoader } from "../../../redux/slices/loaderSlice";
import { getToast } from "../../../utils";
import { TOAST_MESSAGE } from "../../../constants/constants";

const AddEdit = () => {
  const { id } = useParams("id");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [editData, setEditData] = useState({});
  const [profileImage, setProfileImage] = useState("");
  const [coverImage, setCoverImage] = useState("");

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    if (
      values?.age !== "" &&
      values?.age !== null &&
      values?.age !== undefined &&
      (values?.age < 11 || values?.age > 99)
    ) {
      setSubmitting(false);
      return getToast("error", TOAST_MESSAGE.STUDENT_AGE_VALIDATION);
    }
    if (values?.phone?.charAt(0) === "0" && values?.phone?.charAt(9) === "0") {
      setSubmitting(false);
      return getToast("error", TOAST_MESSAGE.PHONE_VALIDATION);
    }
    if (values?.phone?.charAt(0) === "0") {
      setSubmitting(false);
      return getToast("error", TOAST_MESSAGE.PHONE_VALIDATION);
    } else {
      dispatch(toggleLoader({ loader: true }));
      let formdata = new FormData();
      formdata.append("email", values.email);
      formdata.append("name", values.name);
      formdata.append("phone", values.phone);
      formdata.append("gender", values?.gender?.value);
      formdata.append("address", values.address);
      values?.age !== null && formdata.append("age", values.age);
      values?.profile_image &&
        formdata.append("profile_image", values.profile_image);
      values?.background && formdata.append("background", values.background);
      id && formdata.append("id", id);
      postApi(
        id ? APIS.UPDATE_TEACHER : APIS.CREATE_TEACHER,
        Object.fromEntries(formdata),
        true
      )
        .then((res) => {
          dispatch(toggleLoader({ loader: false }));
          setTimeout(() => {
            navigate(-1);
          }, 300);
          getToast(
            "success",
            id ? TOAST_MESSAGE.TEACHER_UPDATED : TOAST_MESSAGE?.TEACHER_ADDED
          );
        })
        .catch((err) => {
          dispatch(toggleLoader({ loader: false }));
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(toggleLoader({ loader: true }));
      postApi(APIS.TEACHER_BY_ID, { id: id })
        .then((res) => {
          setEditData({
            name: res?.payload?.data?.getTeacher.name,
            email: res?.payload?.data?.getTeacher.email,
            address:
              res?.payload?.data?.getTeacher.address !== null
                ? res?.payload?.data?.getTeacher.address
                : "",
            phone: res?.payload?.data?.getTeacher.phone,
            gender: {
              label: res?.payload?.data?.getTeacher?.gender,
              value: res?.payload?.data?.getTeacher?.gender,
            },
            age: res?.payload?.data?.getTeacher.age,
            profile_image: res?.payload?.data?.getTeacher.photo,
            background: res?.payload?.data?.getTeacher.background,
          });
        })
        .catch((err) => {})
        .finally(() => {
          dispatch(toggleLoader({ loader: false }));
        });
    }
  }, []);

  const formik = useFormik({
    initialValues: id ? editData : TEACHER_FORM?.intialvalues,
    onSubmit: onSubmit,
    validationSchema: TEACHER_FORM?.schema,
    enableReinitialize: true,
  });

  const {
    handleSubmit,
    errors,
    values,
    setFieldValue,
    touched,
    setTouched,
    isSubmitting,
  } = formik;

  return (
    <div className="student_list_page sec-padding ">
      <div className="cstm_Ctnr">
        <div className="tab_wpr">
          <form onSubmit={handleSubmit}>
            <ImageUploader
              fieldName={"background"}
              setState={setCoverImage}
              state={coverImage}
              image={
                values?.background !== null &&
                values?.background !== undefined &&
                values?.background !== ""
                  ? values?.background
                  : images?.bgCover
              }
              name={"file"}
              className="bg-upload"
              setFieldValue={setFieldValue}
            />
            <div className="d-flex edit_details mb-30">
              <ImageUploader
                fieldName={"profile_image"}
                setState={setProfileImage}
                state={profileImage}
                setFieldValue={setFieldValue}
                image={
                  values?.profile_image !== null &&
                  values?.profile_image !== undefined &&
                  values?.profile_image !== ""
                    ? values?.profile_image
                    : images?.profile_cover
                }
                name={"file"}
              />
              <div className="prf_description d-flex justify-end align-center">
                <div className="btn_group " style={{ paddingTop: "57px" }}>
                  <button
                    type="button"
                    disabled={isSubmitting}
                    className="btn_reset pink_btn"
                    style={{ marginRight: "20px" }}
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn_reset pink_btn "
                  >
                    {id ? "Update" : "Save"}
                  </button>
                </div>
              </div>
            </div>

            <div className="form_row item2">
              {TEACHER_FORM?.fields?.map((fieldObj, i, arr) => {
                return (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label
                      htmlFor={fieldObj.id || fieldObj.name}
                      style={{
                        color: "#197bbd",
                        fontSize: "16px",
                        fontWeight: "700",
                      }}
                    >
                      {fieldObj?.label}
                    </label>
                    <MixComponents
                      style={{
                        background: "#fdfdfd",
                        border: "1px solid #9e9e9e",
                        borderRadius: "24px",
                        color: "#000",
                        fontSize: "14px",
                        padding: "14px 20px",
                        width: "100%",
                      }}
                      isEdit={id ? true : false}
                      fieldObj={fieldObj}
                      values={values}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      touched={touched}
                      setTouched={setTouched}
                    />
                  </div>
                );
              })}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddEdit;
