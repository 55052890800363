import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PublicRoutes = ({ element, ...rest }) => {
  const token = useSelector((state) => state?.authSlice?.token);

  return (
    <>
      {token === null || token === "" || token === undefined ? (
        element
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
};

export default PublicRoutes;
