import React, { useEffect, useState } from "react";
import "./dashboard.css";
import Grid from "@mui/material/Grid";
import { StatCard } from "../../features/dashboard/StatCard";
import { Table } from "../../components/common/table/Table";
import PieChart from "../../components/common/charts/PieChart";
import { postApi } from "../../api/api.client";
import { APIS } from "../../api/api.constant";
import { DASHBOARD_CONTENT, TOAST_MESSAGE } from "../../constants/constants";
import { ACTION_KEY } from "../../hoc/WithTableHoc";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toggleLoader } from "../../redux/slices/loaderSlice";
import { showModal, hideModal } from "../../redux/slices/modalSlice";
import { MODAL_TYPE } from "../../redux/constant/reduxConstant";
import { getToast } from "../../utils";
import { PERSIST_STORAGE } from "../../api/api.constant";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);
  const [fisrtRender, setFirstRender] = useState(false);
  const persistData = localStorage.getItem(PERSIST_STORAGE);
  const [count, setCount] = useState(0);
  const [dashboardData, setDashboardData] = useState({
    studentStat: {},
    classStat: {},
    teacherStat: {},
    assignmentStat: {},
    todoStat: {},
    classList: [],
    teacherList: [],
    studentChart: {},
    classChart: {},
    teacherChart: {},
    assignmentChart: {},
    todoChart: {},
  });

  useEffect(() => {
    dispatch(toggleLoader({ loader: true }));
    if (count === 0) {
      setTimeout(() => {
        setFirstRender(!fisrtRender);
        setCount(count + 1);
      }, 700);
    }
    if (persistData) {
      let authSlice = JSON.parse(persistData);
      let authStore = JSON.parse(authSlice?.authSlice);
      if (
        authStore?.token !== null &&
        authStore?.token !== undefined &&
        authStore?.token !== ""
      ) {
        const apiRequests = [
          postApi(APIS.STUDENT_STAT_DASHBOARD),
          postApi(APIS.CLASS_STAT_DASHBOARD),
          postApi(APIS.TEACHER_STAT_DASHBOARD),
          postApi(APIS.CLASS_LIST_DASHBOARD),
          postApi(APIS.TEACHER_LIST_DASHBOARD),
          postApi(APIS.STUDENT_BAR_CHART),
          postApi(APIS.CLASS_BAR_CHART),
          postApi(APIS.TEACHER_BAR_CHART),
          postApi(APIS.ASSIGNEMENT_STAT_DASHBOARD),
          postApi(APIS.TODO_STAT_DASHBOARD),
          postApi(APIS.ASSIGNEMENT_BAR_CHART),
          postApi(APIS.TO_DO_BAR_CHART),
        ];
        Promise.all(apiRequests)
          .then(
            ([
              studentStatRes,
              classStatRes,
              teacherStatRes,
              classListRes,
              teacherListRes,
              studentBarChartRes,
              classBarChartRes,
              teacherBarChartRes,
              assignmentStatRes,
              todoStatRes,
              assignmentChartRes,
              todoChart,
            ]) => {
              setDashboardData({
                studentStat: studentStatRes?.payload?.data,
                classStat: classStatRes?.payload?.data,
                teacherStat: teacherStatRes?.payload?.data,
                classList: classListRes?.payload?.data?.result,
                teacherList: teacherListRes?.payload?.data?.resultData,
                studentChart: {
                  data: [
                    [
                      ...studentBarChartRes?.payload?.data?.map((item) => {
                        let percentage = item?.count[0]?.percentage;
                        return Number(percentage.slice(0, -1));
                      }),
                    ],
                  ],
                  options: {
                    title:
                      "Percentage of students registered in last 4 quarters",
                  },
                },
                classChart: {
                  data: [
                    [
                      ...classBarChartRes?.payload?.data?.map((item) => {
                        let percentage = item?.count[0]?.percentage;
                        return Number(percentage.slice(0, -1));
                      }),
                    ],
                  ],
                  options: {
                    title:
                      "Percentage of classes registered in last 4 quarters",
                  },
                },
                teacherChart: {
                  data: [
                    [
                      ...teacherBarChartRes?.payload?.data?.map((item) => {
                        let percentage = item?.count[0]?.percentage;
                        return Number(percentage.slice(0, -1));
                      }),
                    ],
                  ],
                  options: {
                    title:
                      "Percentage of teachers registered in last 4 quarters",
                  },
                },
                assignmentStat: assignmentStatRes?.payload?.data,
                todoStat: todoStatRes?.payload?.data,
                assignmentChart: {
                  data: [
                    [
                      ...assignmentChartRes?.payload?.data?.map((item) => {
                        let percentage = item?.count[0]?.percentage;
                        return Number(percentage.slice(0, -1));
                      }),
                    ],
                  ],
                  options: {
                    title:
                      "Percentage of assignments registered in last 4 quarters",
                  },
                },
                todoChart: {
                  data: [
                    [
                      ...todoChart?.payload?.data?.map((item) => {
                        let percentage = item?.count[0]?.percentage;
                        return Number(percentage.slice(0, -1));
                      }),
                    ],
                  ],
                  options: {
                    title: "Percentage of to-do registered in last 4 quarters",
                  },
                },
              });
            }
          )
          .catch((err) => {
            console.error("Error fetching dashboard data:", err);
          })
          .finally(() => {
            dispatch(toggleLoader({ loader: false }));
          });
      }
    }
  }, [refresh, fisrtRender]);

  const onActionHandler = (e, identifier, data, tableContent) => {
    switch (identifier) {
      case ACTION_KEY.VIEW:
        navigate(`${tableContent?.viewLink}/${data?._id}`);
        break;
      case ACTION_KEY.EDIT:
        navigate(`${tableContent?.editLink}/${data?._id}`);
        break;
      case ACTION_KEY.DELETE:
        dispatch(
          showModal({
            type: MODAL_TYPE.DELETE,
            actionHandler: () => {
              dispatch(toggleLoader({ loader: true }));
              postApi(tableContent?.deletApi, {
                id: data?._id,
              })
                .then((res) => {
                  setRefresh(!refresh);

                  getToast("success", TOAST_MESSAGE.DELETE);
                })
                .catch((err) => { })
                .finally(() => {
                  setTimeout(() => {
                    dispatch(toggleLoader({ loader: false }));
                    dispatch(hideModal());
                  }, 300);
                });
            },
          })
        );
        break;
      case ACTION_KEY.STATUS:
        dispatch(
          showModal({
            type: MODAL_TYPE.STATUS,
            actionHandler: () => {
              dispatch(toggleLoader({ loader: true }));
              postApi(tableContent?.statusChanged, {
                id: data?._id,
                isBlocked: !data?.isBlocked,
              })
                .then((res) => {
                  setRefresh(!refresh);
                  getToast("success", TOAST_MESSAGE.STATUS);
                })
                .catch((err) => {
                  dispatch(toggleLoader({ loader: false }));
                })
                .finally(() => {
                  setTimeout(() => {
                    dispatch(toggleLoader({ loader: false }));
                    dispatch(hideModal());
                  }, 300);
                });
            },
          })
        );
        break;
      case ACTION_KEY.MODAL_EDIT:
        dispatch(
          showModal({
            value: data,
            type: MODAL_TYPE.EDIT_CLASS,
            actionHandler: (values) => {
              dispatch(toggleLoader({ loader: true }));
              postApi(APIS.UPDATE_CLASS, {
                id: values?.id,
                class_title: values?.classname,
              })
                .then((res) => {
                  getToast("success", TOAST_MESSAGE.UPDATED);
                  setRefresh(!refresh);
                })
                .catch((err) => { })
                .finally(() => {
                  setTimeout(() => {
                    dispatch(toggleLoader({ loader: false }));
                    dispatch(hideModal());
                  }, 300);
                });
            },
          })
        );
        break;
      default:
        console.log("handle for this", identifier);
    }
  };

  return (
    <div className="todo-list-page sec-padding min-vh">
      {DASHBOARD_CONTENT?.map((obj, i, arr) => {
        return (
          <div className="cstm_Ctnr" key={`${obj?.label}-${i}`}>
            <div className="cmn_hdr">
              <h3>
                <b>{obj?.label}</b>
              </h3>
            </div>
            <Grid container spacing={5}>
              <Grid item lg={8}>
                <div className="stat_List item4 flex-wrap mt-0">
                  {obj?.statData?.map((item, i) => (
                    <StatCard
                      index={i}
                      key={i}
                      data={item}
                      value={dashboardData?.[obj?.statKey]}
                    />
                  ))}
                </div>
              </Grid>
              <Grid item xs={4}>
                <PieChart
                  data={dashboardData?.[obj?.pieChartKey]?.data}
                  options={dashboardData?.[obj?.pieChartKey]?.options}
                />
              </Grid>
            </Grid>
            {obj?.hasTable && (
              <div style={{ marginBottom: "20px" }}>
                <div className="cmn_hdr">
                  <h3>
                    <b>{obj?.tableLabel}</b>
                  </h3>
                </div>
                <Table
                  tableContent={obj?.tableContent}
                  tableData={dashboardData?.[obj?.tableDataKey]}
                  onActionHandler={onActionHandler}
                  isFilter={false}
                  isPagination={false}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Dashboard;
