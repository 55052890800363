import React, { useState, useEffect } from "react";
import { images } from "../constants/ImageImports";
import { Table } from "../components/common/table/Table";
import { useNavigate, useLocation } from "react-router";
import { APIS } from "../api/api.constant";
import { postApi } from "../api/api.client";
import { hideModal, showModal } from "../redux/slices/modalSlice";
import { useDispatch } from "react-redux";
import { MODAL_TYPE } from "../redux/constant/reduxConstant";
import { getToast } from "../utils";
import { TOAST_MESSAGE } from "../constants/constants";
import { toggleLoader } from "../redux/slices/loaderSlice";

export const ACTION_KEY = {
  VIEW: "view",
  ADD: "add",
  DELETE: "delete",
  EDIT: "edit",
  STATUS: "status",
  MODAL_EDIT: "modal-edit",
};

const STUDENT_COLUMNS = [
  { label: "Name" },
  { label: "Email" },
  { label: "Phone number" },
  { label: "Age" },
  { label: "Status" },
  { label: "Actions" },
];

const ACTION_MENU = [
  { icon: images?.tableView, key: ACTION_KEY.VIEW },
  { icon: images?.tableEdit, key: ACTION_KEY.EDIT },
  { icon: images?.tableDelete, key: ACTION_KEY.DELETE },
];

const PAGE_CONTENT = [
  {
    identifier: "student",
    route: "/student-management",
    columns: STUDENT_COLUMNS,
    pageTitle: "Student List",
    pageButtonLabel: "Add Student",
    listApi: APIS.STUDENT_LIST,
    deletApi: APIS.DELETE_STUDENT,
    statusChanged: APIS.STUDENT_STATUS_CHANGE,
    actionMenu: ACTION_MENU,
    addLink: "/student-add",
    editLink: "/student-edit",
    viewLink: "/student-view",
  },
  {
    identifier: "teacher",
    route: "/teacher-management",
    columns: STUDENT_COLUMNS,
    pageTitle: "Teacher List",
    pageButtonLabel: "Add Teacher",
    deletApi: APIS.DELETE_TEACHER,
    listApi: APIS.TEACHER_LIST,
    statusChanged: APIS.TEACHER_STATUS_CHANGE,
    actionMenu: ACTION_MENU,
    addLink: "/teacher-add",
    editLink: "/teacher-edit",
    viewLink: "/teacher-view",
  },
];

const intialFilter = {
  search: "",
  page: 1,
  pageSize: 10,
};

const WithTableHoc = (WrappedComponent) => {
  const NewWrappedComponent = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [pagination, setPagination] = useState({});
    const [refresh, setRefresh] = useState(false);
    const [tableContent, setTableContent] = useState(
      PAGE_CONTENT?.find((obj, i, arr) => obj.route === location?.pathname)
    );
    const [tableData, setTableData] = useState([]);
    const [filter, setFilter] = useState(intialFilter);
    useEffect(() => {
      dispatch(toggleLoader({ loader: true }));
      postApi(tableContent?.listApi, { ...filter })
        .then((res) => {
          setTableData(res?.payload?.data?.resultData);
          setPagination(res?.payload?.data?.pagination);
        })
        .catch((err) => {})
        .finally(() => {
          dispatch(toggleLoader({ loader: false }));
        });
    }, [tableContent, refresh, filter]);

    const onActionHandler = (e, identifier, data, tableContent) => {
      switch (identifier) {
        case ACTION_KEY.VIEW:
          navigate(`${tableContent?.viewLink}/${data?._id}`);
          break;
        case ACTION_KEY.EDIT:
          navigate(`${tableContent?.editLink}/${data?._id}`);
          break;
        case ACTION_KEY.DELETE:
          dispatch(
            showModal({
              type: MODAL_TYPE.DELETE,
              actionHandler: () => {
                dispatch(toggleLoader({ loader: true }));
                postApi(tableContent?.deletApi, {
                  id: data?._id,
                })
                  .then((res) => {
                    setRefresh(!refresh);
                    if (tableData?.length === 1) {
                      setFilter({ ...filter, page: filter?.page - 1 });
                    } else {
                      setFilter({ ...filter, page: filter?.page });
                    }
                    getToast("success", TOAST_MESSAGE.DELETE);
                  })
                  .catch((err) => {})
                  .finally(() => {
                    dispatch(toggleLoader({ loader: false }));
                    dispatch(hideModal());
                  });
              },
            })
          );
          break;
        case ACTION_KEY.STATUS:
          dispatch(
            showModal({
              type: MODAL_TYPE.STATUS,
              actionHandler: () => {
                dispatch(toggleLoader({ loader: true }));
                postApi(tableContent?.statusChanged, {
                  id: data?._id,
                  isBlocked: !data?.isBlocked,
                })
                  .then((res) => {
                    setRefresh(!refresh);
                    getToast("success", TOAST_MESSAGE.STATUS);
                  })
                  .catch((err) => {
                    dispatch(toggleLoader({ loader: false }));
                  })
                  .finally(() => {
                    dispatch(toggleLoader({ loader: false }));
                    dispatch(hideModal());
                  });
              },
            })
          );
          break;
        default:
          console.log("handle for this", identifier);
      }
    };

    return (
      <>
        <div className="student_list_page sec-padding">
          <div className="cstm_Ctnr">
            <div className="cmn_hdr">
              <h3>
                <b>{tableContent?.pageTitle}</b>
              </h3>
              <div className="btn_group">
                <button
                  className="btn_reset pink_btn"
                  onClick={() => navigate(tableContent?.addLink)}
                >
                  <span className="me-10">
                    <img src={images?.plus} alt="img" />
                  </span>
                  {tableContent?.pageButtonLabel}
                </button>
              </div>
            </div>
            <Table
              setPagination={setPagination}
              tableContent={tableContent}
              tableData={tableData}
              onActionHandler={onActionHandler}
              setFilter={setFilter}
              filter={filter}
              pagination={pagination}
              refresh={refresh}
            />
          </div>
        </div>
        <WrappedComponent {...props} />
      </>
    );
  };

  return NewWrappedComponent;
};

export default WithTableHoc;
