import React, { useEffect, useState } from "react";
import { ViewProfile } from "../../../components/common/viewProfile/ViewProfile";
import { Tabbing } from "../../../components/common/tabbing/Tabbing";
import { useParams } from "react-router";
import { postApi } from "../../../api/api.client";
import { APIS } from "../../../api/api.constant";
import { useDispatch } from "react-redux";
import { toggleLoader } from "../../../redux/slices/loaderSlice";
import {
  PresentTask,
  CompletedTask,
  ShowTab,
  UpcomingTask,
} from "../../../features/todoListTabContent";
import { images } from "../../../constants/ImageImports";

const TEACHER_VIEW_FIEDLS = [
  { title: "Mobile Number", identifier: "phone" },
  { title: "Address", identifier: "address" },
  { title: "Pronoun", identifier: "gender" },
  { title: "Age", identifier: "age" },
];

const createTeacherdata = (teacherData) => {
  let temp = {
    name: teacherData?.name,
    email: teacherData?.email,
    address: teacherData?.address,
    gender: teacherData?.gender,
    phone: teacherData?.phone,
    photo: teacherData?.photo,
    background: teacherData?.background,
    id: teacherData?._id,
    age: teacherData?.age,
  };
  return temp;
};

export const TeacherView = () => {
  const { id } = useParams("id");
  const dispatch = useDispatch();
  const [profileData, setProfileData] = useState({});
  const [count, setCount] = useState({
    all: "",
    upcoming: "",
    present: "",
    complete: "",
  });

  useEffect(() => {
    dispatch(toggleLoader({ loader: true }));
    const apiRequests = [
      postApi(APIS.TO_DO_LIST, {
        todo_type: "all",
        page: 1,
        pageSize: 11,
        teacher_id: id,
      }),
      postApi(APIS.TO_DO_LIST, {
        todo_type: "upcoming",
        page: 1,
        pageSize: 11,
        teacher_id: id,
      }),
      postApi(APIS.TO_DO_LIST, {
        todo_type: "current",
        page: 1,
        pageSize: 11,
        teacher_id: id,
      }),
      postApi(APIS.TO_DO_LIST, {
        todo_type: "past",
        page: 1,
        pageSize: 11,
        teacher_id: id,
      }),
    ];
    Promise.all(apiRequests)
      .then(([allCount, upcommingCount, currentCount, pastCount]) => {
        setCount({
          all: allCount?.payload?.data?.length,
          upcoming: upcommingCount?.payload?.data?.length,
          present: currentCount?.payload?.data?.length,
          complete: pastCount?.payload?.data?.length,
        });
      })
      .catch(() => {})
      .finally(() => {
        dispatch(toggleLoader({ loader: false }));
      });
  }, []);

  const TodoListTabbing = [
    {
      title: "Show All",
      content: <ShowTab byId={{ idType: 2, id: id }} />,
      icon: images?.all,
      task: count?.all,
    },
    {
      title: "Upcoming",
      content: <UpcomingTask byId={{ idType: 2, id: id }} />,
      icon: images?.present,
      task: count?.upcoming,
    },
    {
      title: "Present To-do",
      content: <PresentTask byId={{ idType: 2, id: id }} />,
      icon: images?.upcoming,
      task: count?.present,
    },
    {
      title: "Past To-do List",
      content: <CompletedTask byId={{ idType: 2, id: id }} />,
      icon: images?.completed,
      task: count?.complete,
    },
  ];

  useEffect(() => {
    dispatch(toggleLoader({ loader: true }));
    postApi(APIS.TEACHER_BY_ID, {
      id: id,
    })
      .then((res) => {
        setProfileData(
          createTeacherdata({
            ...res?.payload?.data?.getTeacher,
            classData: res?.payload?.data?.classData,
          })
        );
      })
      .catch((err) => {})
      .finally(() => {
        dispatch(toggleLoader({ loader: false }));
      });
  }, [id]);

  return (
    <div className="view_profile_page sec-padding">
      <div className="cstm_Ctnr">
        <ViewProfile
          data={profileData}
          navigationRoute={"/teacher-edit"}
          fields={TEACHER_VIEW_FIEDLS}
        />

        <div className="tab_btn_full mt-40">
          <Tabbing tabs={TodoListTabbing} />
        </div>
      </div>
    </div>
  );
};
export default TeacherView;
