import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  Dashboard,
  TodoList,
  Notification,
  Privacy,
  TermAndCondition,
  Setting,
  Login,
  ForgotPassword,
  StudentList,
  Teacher,
  AddEditTeacher,
  AddEditStudent,
  StudentView,
  TeacherView,
  ResetPassword,
  ViewTermsAndConditions,
  ViewPolicy,
} from "../pages";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PrivateRoutes />}>
          <Route index element={<Dashboard />} />
          <Route path="student-management" element={<StudentList />} />
          <Route path={"student-add"} element={<AddEditStudent />} />
          <Route path={`student-edit/:id`} element={<AddEditStudent />} />
          <Route path={`student-view/:id`} element={<StudentView />} />
          <Route path="teacher-management" element={<Teacher />} />
          <Route path={`teacher-edit/:id`} element={<AddEditTeacher />} />
          <Route path={`teacher-add`} element={<AddEditTeacher />} />
          <Route path={`teacher-view/:id`} element={<TeacherView />} />
          <Route path="notification" element={<Notification />} />
          <Route path="todo-list" element={<TodoList />} />
          <Route path="privacy-policy" element={<Privacy />} />
          <Route path="setting" element={<Setting />} />
          <Route path="term-and-conditions" element={<TermAndCondition />} />
          <Route
            path="view-term-and-conditions"
            element={<ViewTermsAndConditions />}
          />
          <Route path="view-privacy-policy" element={<ViewPolicy />} />
        </Route>

        <Route path="/login" element={<PublicRoutes element={<Login />} />} />
        <Route
          path="forgot-password"
          element={<PublicRoutes element={<ForgotPassword />} />}
        />
        <Route
          path="reset-password"
          element={<PublicRoutes element={<ResetPassword />} />}
        />

        <Route path="*" element={<PublicRoutes element={<Login />} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
