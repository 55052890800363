import React from "react";
import { useDispatch } from "react-redux";
import { images } from "../../constants/ImageImports";
import { hideModal } from "../../redux/slices/modalSlice";
import { useFormik } from "formik";
import { FieldError } from "../common";
import * as Yup from "yup";
import { getToast } from "../../utils";
import { TOAST_MESSAGE } from "../../constants/constants";

export const EditClass = ({ value, actionHandler }) => {
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    if (value?.class_title === values?.classname) {
      getToast("error", TOAST_MESSAGE.CAN_NOT_SAME);
      dispatch(hideModal());
      return;
    } else {
      actionHandler(values);
      dispatch(hideModal());
    }
  };

  const closeModal = () => {
    dispatch(hideModal());
  };

  const formik = useFormik({
    initialValues: {
      classname: value?.class_title,
      id: value?._id,
    },
    onSubmit: onSubmit,
    validationSchema: Yup.object().shape({
      classname: Yup.string()
        .max(15, "Must be less than 15 characters")
        .required("Required*"),
    }),
    enableReinitialize: true,
  });

  const {
    handleSubmit,
    errors,
    values,
    setFieldValue,
    touched,
    setTouched,
    isSubmitting,
    dirty,
  } = formik;

  return (
    <div className="mdl_inr addClass_mdl">
      <div className="cross_icon" onClick={closeModal}>
        <img src={images?.modelCross} alt="img" className="prft_img" />
      </div>
      <div className="mdl_hdr">
        <h4>Edit Class</h4>
      </div>
      <div className="mdl_ctnt">
        <form
          className="form_row"
          onSubmit={handleSubmit}
          style={{ rowGap: "2px" }}
        >
          <>
            <input
              style={{
                background: "#fdfdfd",
                border: "1px solid #9e9e9e",
                borderRadius: "24px",
                color: "#000",
                fontSize: "14px",
                padding: "14px 20px",
                width: "100%",
              }}
              value={values?.classname}
              name="classname"
              type="text"
              onChange={(e) => {
                setFieldValue("classname", e.target.value);
              }}
            />
            <FieldError
              errors={errors}
              touched={touched}
              fieldObj={{ name: "classname" }}
            />
          </>
          <button
            className="btn_reset blue_btn"
            type="submit"
            disabled={!dirty}
            style={{ backgroundColor: !dirty && "lightgray" }}
          >
            Save
          </button>
        </form>
      </div>
    </div>
  );
};
