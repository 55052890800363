import React from "react";
// import { NotificationItem } from "../../features/notification/NotificationItem";
// import "./Notification.css";

const Notification = () => {
  let a = [
    'Your task "Task description" has been reviewed by teacher. It needs rework. Please upload file again.',
    'Your task "Task description" has been reviewed by teacher. Its approved now.',
    'Your task "Task description" has been reviewed by teacher. It needs rework. Please upload file again.',
    'Your task "Task description" has been reviewed by teacher. It needs rework. Please upload file again.',
    'Your task "Task description" has been reviewed by teacher. It needs rework. Please upload file again.',
    'Your task "Task description" has been reviewed by teacher. It needs rework. Please upload file again.',
    'Your task "Task description" has been reviewed by teacher. It needs rework. Please upload file again.',
    'Your task "Task description" has been reviewed by teacher. It needs rework. Please upload file again.',
    'Your task "Task description" has been reviewed by teacher. It needs rework. Please upload file again.',
    'Your task "Task description" has been reviewed by teacher. It needs rework. Please upload file again.',
    'Your task "Task description" has been reviewed by teacher. It needs rework. Please upload file again.',
    'Your task "Task description" has been reviewed by teacher. It needs rework. Please upload file again.',
    'Your task "Task description" has been reviewed by teacher. It needs rework. Please upload file again.',
    'Your task "Task description" has been reviewed by teacher. It needs rework. Please upload file again.',
    'Your task "Task description" has been reviewed by teacher. It needs rework. Please upload file again.',
    'Your task "Task description" has been reviewed by teacher. It needs rework. Please upload file again.',
    'Your task "Task description" has been reviewed by teacher. It needs rework. Please upload file again.',
    'Your task "Task description" has been reviewed by teacher. It needs rework. Please upload file again.',
    'Your task "Task description" has been reviewed by teacher. It needs rework. Please upload file again.',
    'Your task "Task description" has been reviewed by teacher. It needs rework. Please upload file again.',
  ];
  return (
    <div className="notification-page sec-padding">
      <div className="cstm_Ctnr">
        <div className="ntf_List">
          {/* {a.map((item, i) => (
            <NotificationItem text={item} index={i} key={i} />
          ))} */}
        </div>
      </div>
    </div>
  );
};

export default Notification;
