import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  token: null,
  isLoggedIn: false,
  email: "",
  role: "",
  userId: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.name = action?.payload?.name ? action?.payload?.name : "Admin";
      state.isLoggedIn = true;
      state.token = action.payload.token;
      state.email = action.payload.email;
      state.role = action.payload.role;
      state.userId = action.payload.userId;
    },
    logoutUser: (state) => {
      state.name = "";
      state.isLoggedIn = false;
      state.token = null;
      state.email = "";
      state.role = "";
      state.userId = "";
    },
  },
});

export const { setUser, logoutUser } = authSlice.actions;

export default authSlice;
