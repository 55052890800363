import React, { useState } from "react";
import "./tabbing.css";

export const Tabbing = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className="tab-container">
      <div className="tab-titles">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`tab-title ${activeTab === index ? "active" : ""}`}
            onClick={() => handleTabClick(index)}
          >
            <span className="icon">
              <img src={tab?.icon} alt="icon" />
            </span>
            <span className="tab_text">{tab.title}</span>
            {tab?.task > 0 && <span className="tab_task">{tab.task}</span>}
          </div>
        ))}
      </div>
      <div className="tab-content">{tabs[activeTab].content}</div>
    </div>
  );
};
