import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styles from "./Header.module.css";
import { Button, Menu, Avatar, Fade } from "@mui/material";
import { images } from "../../../constants/ImageImports";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { showModal } from "../../../redux/slices/modalSlice";
import { MODAL_TYPE } from "../../../redux/constant/reduxConstant";
import { Modal } from "../../modals";
import { useSelector } from "react-redux";

const STUDENT_ROUTE = [
  "/student-management",
  "/student-add",
  "/student-edit",
  "/student-view",
];

const TEACHER_ROUTE = [
  "/teacher-management",
  "/teacher-edit",
  "/teacher-add",
  "/teacher-view",
];

const headerFromRoute = (pathName, array) => {
  if (array.includes(pathName)) {
    return true;
  }

  for (const route of array) {
    const pattern = new RegExp(`^${route}/\\w+$`);

    if (pattern.test(pathName)) {
      return true;
    }
  }

  return false;
};

const Header = () => {
  const currentUser = useSelector((state) => state?.authSlice);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const pathName = window.location.pathname;
  const path = pathName.split("/");

  const open = anchorEl;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutModalHandler = () => {
    dispatch(showModal({ type: MODAL_TYPE?.LOGOUT }));
  };

  useEffect(() => {}, [navigate]);

  return (
    <>
      <Modal />
      <header className={styles?.main_Hdr}>
        <div className={`cstm_Ctnr ${styles?.hdr_Inr}`}>
          <h3 className={styles?.hdr_Title}>
            {pathName === "/notification"
              ? "Notification"
              : pathName === "/term-and-conditions"
              ? "Terms & Conditions"
              : pathName === "/privacy-policy"
              ? "Privacy Policy"
              : pathName === "/todo-list"
              ? "To Do List"
              : headerFromRoute(pathName, STUDENT_ROUTE)
              ? "Student Management"
              : headerFromRoute(pathName, TEACHER_ROUTE)
              ? "Teacher Management"
              : pathName === "/setting"
              ? "Settings"
              : "Dashboard"}
          </h3>
          <Button
            id="logout_Btn"
            aria-controls={open ? "logout_Btn_Menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={{ padding: 0 }}
          >
            <Avatar sx={{ width: "30px", height: "30px" }}>
              {currentUser?.name?.[0]}
            </Avatar>
            <span className="text">{currentUser?.name}</span>
            <img src={images?.path} alt="icon" />
          </Button>
          <Menu
            id="logout_Btn_Menu"
            aria-labelledby="logout_Btn"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <div className={styles?.logout_Btn_Menu}>
              <Link to={"/"} className={styles?.logout_name}>
                {currentUser?.name}
              </Link>
              <a
                href={`mailto:${currentUser?.email}`}
                className={styles?.email}
              >
                {currentUser?.email}
              </a>
              <div
                className={styles?.lgt_btn}
                onClick={() => {
                  logoutModalHandler();
                }}
              >
                <img src={images?.logout} alt="img" />
                logout
              </div>
            </div>
          </Menu>
        </div>
      </header>
    </>
  );
};

export default Header;
