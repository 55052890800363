import React from "react";
import { ACTION_KEY } from "../../../hoc/WithTableHoc";

export const BlockButton = ({ data, onActionHandler, tableContent }) => {
  return (
    <div
      className={data?.isBlocked ? "block_btn" : "block_btn unblock_btn"}
      onClick={(e) =>
        onActionHandler(e, ACTION_KEY?.STATUS, data, tableContent)
      }
    >
      {data?.isBlocked ? "Block" : "Unblock"}
    </div>
  );
};
