import React, { useState } from "react";
import "./taskItem.css";
import { DownloadButton } from "../buttons/DownloadButton";
import { images } from "../../../constants/ImageImports";
import { TaskStepper } from "./TaskStepper";

const CheckIcon = ({ isActive }) => {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 0.826172C6.28009 0.826172 0 7.10627 0 14.8262C0 22.5461 6.28009 28.8262 14 28.8262C21.7199 28.8262 28 22.5461 28 14.8262C28 7.10627 21.7199 0.826172 14 0.826172ZM21.0957 11.8594L13.5123 19.4426C13.2848 19.6701 12.9861 19.7846 12.6875 19.7846C12.3889 19.7846 12.0902 19.6701 11.8627 19.4426L8.07111 15.651C7.6148 15.1949 7.6148 14.4575 8.07111 14.0014C8.5272 13.5451 9.26439 13.5451 9.7207 14.0014L12.6875 16.9682L19.4461 10.2098C19.9022 9.75347 20.6394 9.75347 21.0957 10.2098C21.5518 10.6659 21.5518 11.4031 21.0957 11.8594Z"
        fill={isActive ? "#197BBD" : "#9E9E9E"}
      />
    </svg>
  );
};

export const TaskItems = ({ item, type, isAssignment, allData }) => {
  const [show, setShow] = useState(false);
  const [stepper, setStepper] = useState(false);

  let link = item?.assignment?.video?.link1 || item?.assignment?.video?.link2 || item?.assignment?.video?.uploadedLink2 || item?.assignment?.video?.uploadedLink1;

  let assignmentlink = item?.assignment?.assignment?.link || item?.assignment?.assignment?.uploadedLink

  const TaskTop = ({ item, show, setShow, setStepper, isAssignment }) => {
    const handleStepperShow = () => {
      setStepper(!stepper);
    };
    return (
      <div
        className={`task_item_top ${item.taskType !== "upcoming" ? "" : "pe-none"
          }`}
      >
        <div
          className={`task_item_Icon cursor_pointer ${item.taskType === "upcoming" ? "pe-none" : ""
            }`}
          onClick={handleStepperShow}
        >
          <div
            className={
              item?.taskType !== "upcoming"
                ? "task_item_Icon cursor_pointer"
                : "task_item_Icon cursor_pointer pe-none"
            }
            onClick={() => {
              setShow(!show);
              setStepper(false);
            }}
          >
            {isAssignment ? (
              <img src={images?.pdfIcon} alt="img" />
            ) : (
              <CheckIcon isActive={!!item?.taskStatus?.is_submited} />
            )}
          </div>
        </div>
        <h5
          className="cursor_pointer"
          style={{ textTransform: "capitalize" }}
          onClick={handleStepperShow}
        >
          {isAssignment ? item.assessment_title : item.task_title}
        </h5>
        {isAssignment && type === "video" && (
          <div className="task_item_btns">
            <DownloadButton item={link} />
          </div>
        )}
        {isAssignment && type === "assignment" && (
          <div className="task_item_btns">
            <DownloadButton item={assignmentlink} />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={`task_item ${show ? "show" : ""}`}>
      <TaskTop
        isAssignment={isAssignment}
        item={item}
        show={show}
        setShow={setShow}
        setStepper={setStepper}
      />
      {stepper && (
        <TaskStepper itemData={isAssignment ? allData?.studentData : item?.taskStatus} isAssignment={isAssignment} />
      )}
    </div>
  );
};
