import React, { useEffect, useState } from "react";
import FieldError from "../fieldError";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const MixComponents = ({
  fieldObj,
  values,
  errors,
  setFieldValue,
  touched,
  setTouched,
  style,
  isEdit,
  options,
}) => {
  return (
    <>
      {fieldObj?.component === "input" && (
        <>
          <input
            disabled={isEdit && fieldObj?.isDisabled}
            onBlur={() => {
              setTouched({ ...touched, [fieldObj?.name]: true });
            }}
            style={{
              ...style,
              ...fieldObj?.style,
              cursor: isEdit && fieldObj?.isDisabled && "not-allowed",
            }}
            value={values?.[fieldObj?.name]}
            name={fieldObj?.name}
            type={fieldObj?.type}
            placeholder={fieldObj?.placeholder}
            onChange={(e) => {
              setFieldValue(fieldObj?.name, e.target.value);
            }}
          />
          <FieldError errors={errors} touched={touched} fieldObj={fieldObj} />
        </>
      )}
      {fieldObj?.component === "select" && (
        <>
          <FormControl className={"input_wpr select__bx"}>
            <Select
              onBlur={() => {
                setTouched({ ...touched, [fieldObj?.name]: true });
              }}
              name={fieldObj?.name}
              value={values?.[fieldObj?.name]?.value || ""}
              onChange={(event) => {
                setFieldValue(fieldObj?.name, event?.target);
              }}
              style={{
                ...fieldObj?.style,
                cursor: "pointer",
                alignItems: "center",
                height: "50px",
                background: fieldObj?.isDisabled && "lightgray",
              }}
              placeholder={fieldObj?.placeholder}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="" disabled>
                {fieldObj?.placeholder}
              </MenuItem>
              {fieldObj?.classOptions
                ? options?.map((item, i) => {
                    return (
                      <MenuItem value={item?.value} key={i}>
                        {item?.label}
                      </MenuItem>
                    );
                  })
                : fieldObj?.options?.map((item, i) => {
                    return (
                      <MenuItem value={item.value} key={i}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
            </Select>
          </FormControl>
          <FieldError errors={errors} touched={touched} fieldObj={fieldObj} />
        </>
      )}
      {fieldObj?.component === "textarea" && (
        <>
          <textarea
            onBlur={() => {
              setTouched({ ...touched, [fieldObj?.name]: true });
            }}
            key={fieldObj?.name}
            name={fieldObj?.name}
            value={values?.[fieldObj?.name]}
            style={{
              ...style,
              padding: "10px 20px",
              ...fieldObj?.style,
              background: fieldObj?.isDisabled && "lightgray",
            }}
            placeholder={fieldObj?.placeholder}
            onChange={(e) => {
              setFieldValue(fieldObj?.name, e.target.value);
            }}
          />
          <FieldError errors={errors} touched={touched} fieldObj={fieldObj} />
        </>
      )}
    </>
  );
};

export default MixComponents;
