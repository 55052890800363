import React, { useState, useEffect } from "react";
import { postApi } from "../../api/api.client";
import { APIS } from "../../api/api.constant";
import { useDispatch } from "react-redux";
import { toggleLoader } from "../../redux/slices/loaderSlice";
import { getToast } from "../../utils";
import { TOAST_MESSAGE } from "../../constants/constants";
import { ADMIN_INFO } from "../../constants/formik";
import { MixComponents } from "../../components/common";
import { useFormik } from "formik";

export const ContactUs = () => {
  const dispatch = useDispatch();
  const [adminData, setAdminData] = useState({});

  const onSubmit = (values) => {
    dispatch(toggleLoader({ loader: true }));
    postApi(APIS.POST_ADMIN_INFO, { ...values })
      .then((res) => {
        getToast("success", TOAST_MESSAGE.UPDATE_USER);
      })
      .catch((err) => {})
      .finally(() => {
        dispatch(toggleLoader({ loader: false }));
      });
  };

  useEffect(() => {
    dispatch(toggleLoader({ loader: true }));
    postApi(APIS.GET_ADMIN_INFO, {})
      .then((res) => {
        if (res?.payload?.data === null) {
        } else {
          setAdminData({
            ...res?.payload?.data,
          });
        }
      })
      .catch((err) => {})
      .finally(() => {
        dispatch(toggleLoader({ loader: false }));
      });
  }, []);

  const formik = useFormik({
    initialValues: adminData || ADMIN_INFO?.INITIAL_VALUES,
    onSubmit: onSubmit,
    validationSchema: ADMIN_INFO?.SCHEMA,
    enableReinitialize: true,
  });

  const { handleSubmit, errors, values, setFieldValue, touched, setTouched } =
    formik;
  return (
    <div className="tab_wpr">
      <form onSubmit={handleSubmit}>
        <div className="tab_hdg_wpr">
          <h5 className="tab_hdg">Contact Us</h5>
          <div className="btn_group">
            <button className="pink_btn btn_reset" type="submit">
              Submit
            </button>
          </div>
        </div>
        <div className="contact_details">
          <div className="prf_description">
            <div
              className="edit_fields"
              style={{
                display: "flex",
                marginTop: "30px",
                justifyContent: "space-between",
                width: "1100px",
              }}
            >
              {ADMIN_INFO.FIELDS.map((fieldObj, i, arr) => {
                return (
                  <div className="" key={`${fieldObj?.name}-${i}`}>
                    <div className="prf_label">{fieldObj?.label}</div>
                    <div className="prf_text">
                      <MixComponents
                        isEdit={true}
                        fieldObj={fieldObj}
                        values={values}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        setTouched={setTouched}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
