import React, { useState } from "react";
import styles from "./Sidebar.module.css";
import { images } from "../../../constants/ImageImports";
import Tooltip from "@mui/material/Tooltip";
import { Link, NavLink } from "react-router-dom";
import { SIDE_BAR_MENU } from "../../../constants/constants";

const ROUTE = [
  { path: "/student-management", value: "/student-management" },
  { path: "/student-add", value: "/student-management" },
  { path: "/student-edit", value: "/student-management" },
  { path: "/student-view", value: "/student-management" },
  { path: "/teacher-management", value: "/teacher-management" },
  { path: "/teacher-edit", value: "/teacher-management" },
  { path: "/teacher-add", value: "/teacher-management" },
  { path: "/teacher-view", value: "/teacher-management" },
];

const headerFromRoute = (pathName, link) => {
  if (ROUTE.includes(pathName)) {
    return true;
  }

  for (const route of ROUTE) {
    const pattern = new RegExp(`^${route?.path}/\\w+$`);
    const secondpattern = new RegExp(`^${route?.path}`);
    if (
      (pattern.test(pathName) || secondpattern.test(pathName)) &&
      link === route?.value
    ) {
      return true;
    }
  }

  return false;
};

const staticCheck = (pathName, link) => {
  if (
    pathName === "/view-term-and-conditions" &&
    link === "/term-and-conditions"
  ) {
    return true;
  }
  if (pathName === "/view-privacy-policy" && link === "/privacy-policy") {
    return true;
  } else {
    return false;
  }
};

const Sidebar = () => {
  const pathName = window.location.pathname;
  const [show, setShow] = useState(true);

  return (
    <div className={`${show ? styles.sidebar : styles.minSidebar}`}>
      <div className={styles.navBrand}>
        <Link to={"/"}>
          <img src={show ? images?.logo : images?.minLogo} alt="logo" />
        </Link>
      </div>
      <div
        className={`${
          show
            ? styles.minimizeBtn
            : styles.minimizeBtn + " " + styles.minimizeBtnCls
        }`}
        onClick={() => setShow(!show)}
      >
        <img src={images?.minimizeIcon} className="w-100" alt="minimize btn" />
      </div>
      <ul className={styles.sidebarNav}>
        {SIDE_BAR_MENU.map((obj, i, arr) => {
          return (
            <li key={`${obj.title}-${i}`}>
              <NavLink to={obj?.link}>
                <span>
                  <Tooltip title={obj?.title} placement="bottom-start">
                    <img src={obj?.icon} alt={obj?.title} />
                  </Tooltip>
                </span>
                <span
                  className={styles.navtext}
                  style={{
                    backgroundColor:
                      (headerFromRoute(pathName, obj?.link) ||
                        staticCheck(pathName, obj?.link)) &&
                      "white",
                    color:
                      (headerFromRoute(pathName, obj?.link) ||
                        staticCheck(pathName, obj?.link)) &&
                      "black",
                  }}
                >
                  {obj?.title}
                </span>
              </NavLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Sidebar;
