import React from "react";
import { useDispatch } from "react-redux";
import { images } from "../../constants/ImageImports";
import { hideModal } from "../../redux/slices/modalSlice";
import { useNavigate } from "react-router-dom";
import { toggleLoader } from "../../redux/slices/loaderSlice";
import { logoutUser } from "../../redux/slices/authSlice";

export const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(hideModal());
  };

  const logout = () => {
    dispatch(toggleLoader({ loader: true }));
    closeModal();
    dispatch(logoutUser());
    localStorage.clear("token");
    dispatch(toggleLoader({ loader: false }));
    navigate("/login?success=true");
  };
  return (
    <div className="mdl_inr logout_mdl img_overlay">
      <div className="cross_icon" onClick={closeModal}>
        <img src={images?.modelCross} alt="img" className="prft_img" />
      </div>
      <div className="mdl_ctnt">
        <h5>Are you sure you want to logout?</h5>
        <div className="btn-group mt-30">
          <button className="btn_reset pink_btn" onClick={logout}>
            Logout
          </button>
          <button className="btn_reset blue_btn" onClick={closeModal}>
            Cancel{" "}
          </button>
        </div>
      </div>
    </div>
  );
};
