import React, { useEffect } from "react";
import { WithTodoListHoc } from "../../hoc";

const UpcomingTask = (props) => {
  useEffect(() => {
    props.setApiType("upcoming");
  });
  return <></>;
};
export default WithTodoListHoc(UpcomingTask);
