import { images } from "./ImageImports";
import { APIS } from "../api/api.constant";
import { ACTION_KEY } from "../hoc/WithTableHoc";

const TEACHER_TABLE_ACTION_MENU = [
  { icon: images?.tableView, key: ACTION_KEY.VIEW },
  { icon: images?.tableEdit, key: ACTION_KEY.EDIT },
  { icon: images?.tableDelete, key: ACTION_KEY.DELETE },
];

const CLASS_TABLE_ACTION_MENU = [
  { icon: images?.tableEdit, key: ACTION_KEY.MODAL_EDIT },
  { icon: images?.tableDelete, key: ACTION_KEY.DELETE },
];

const TEACHER_TABLE_COLUMNS = [
  { label: "Name" },
  { label: "Email" },
  { label: "Phone number" },
  { label: "Age" },
  { label: "Status" },
  { label: "Actions" },
];

const CLASS_TABLE_COLUMNS = [
  { label: "S.No" },
  { label: "Class name" },
  { label: "Created Date" },
  { label: "No. of student" },
  { label: "Actions" },
];

export const actionIcons = {
  VIEW: "VIEW",
  EDIT: "EDIT",
  DELETE: "DELETE",
};

export const COMMON_PHONE_REGEX = /0{11,}/;

export const TOAST_MESSAGE = {
  STUDENT_AGE_VALIDATION: 'Please enter age between 10 and 100',
  WRONG_FILE: "Please choose a valid image format (jpg,jpeg,png,svg)",
  SIZE_ERROR: "Image size should not exceed 5MB",
  LOGIN: "Login successfully",
  LOGOUT: "Logout successfully",
  NO_INTERNET: "Please check you internet connection",
  UN_AUTHORISED: "Un-authorized User",
  UPDATE_USER: "Updated successfully",
  DELETE: "Deleted successfully",
  STATUS: "Status changed successfully",
  PASSWORD_UPDATED: "Password updated successfully",
  MESSAGE_SENT: "Message sent successfully",
  LINK_SENT: "Link sent successfully",
  EMPTY_TEXT: "Please enter some text",
  STUDENT_ADDED: "Student added successfully",
  STUDENT_UPDATED: "Student updated successfully",
  TEACHER_ADDED: "Teacher added successfully",
  TEACHER_UPDATED: "Teacher updated successfully",
  UPDATED: "Updated successfully",
  WENT_WRONG: "Something went wrong",
  CAN_NOT_SAME: "Content can not be same",
  AGE_VALIDATE: "Age must be greater than 10",
  PHONE_VALIDATION: "Please enter valid phone number",
  SAME_PASSWORD: "Old Password and New Password cannot be same",
};

/* InterLinks Path */

export const studentInterLinks = [
  "/student-management",
  "/student-edit",
  "/student-info",
];

export const SIDE_BAR_MENU = [
  { link: "/", title: "Dashboard", icon: images?.homeIcon },
  {
    link: "/student-management",
    title: "Student Management",
    icon: images?.studentManagment,
  },
  {
    link: "/teacher-management",
    title: "Teacher Management",
    icon: images?.studentManagment,
  },
  { link: "/todo-list", title: "To-do Management", icon: images?.toDoIcon },

  { link: "/setting", title: "Settings", icon: images?.settingIcon },
  {
    link: "/term-and-conditions",
    title: "Terms & Conditions",
    icon: images?.termIcon,
  },
  {
    link: "/privacy-policy",
    title: "Privacy Policy",
    icon: images?.policyIcon,
  },
];

export const dashboardStats = [
  {
    description: "Total students registered",
    icon: images?.teacherStatIcon1,
    identifier: "totalStudents",
  },
  {
    description: "Total students registered today",
    icon: images?.teacherStatIcon2,
    identifier: "studentsRegisteredToday",
  },
  {
    description: "Total students registered in this month",
    icon: images?.teacherStatIcon3,
    identifier: "studentsRegisteredThisMonth",
  },
  {
    description: "Total students registered in this year",
    icon: images?.teacherStatIcon4,
    identifier: "studentsRegisteredThisYear",
  },
];
export const classStats = [
  {
    description: "Total classes registered",
    icon: images?.teacherStatIcon1,
    identifier: "totalClass",
  },
  {
    description: "Total classes registered today",
    icon: images?.teacherStatIcon2,
    identifier: "classRegisteredToday",
  },
  {
    description: "Total classes registered in this month",
    icon: images?.teacherStatIcon3,
    identifier: "classRegisteredThisMonth",
  },
  {
    description: "Total classes registered in this year",
    icon: images?.teacherStatIcon4,
    identifier: "classRegisteredThisYear",
  },
];
export const teacherStats = [
  {
    description: "Total teachers registered",
    icon: images?.teacherStatIcon1,
    identifier: "totalTeacher",
  },
  {
    description: "Total teachers registered today",
    icon: images?.teacherStatIcon2,
    identifier: "teacherRegisteredToday",
  },
  {
    description: "Total teachers registered in this month",
    icon: images?.teacherStatIcon3,
    identifier: "teacherRegisteredThisMonth",
  },
  {
    description: "Total teachers registered in this year",
    icon: images?.teacherStatIcon4,
    identifier: "teacherRegisteredThisYear",
  },
];
export const assignmentStats = [
  {
    description: "Total assignment registered",
    icon: images?.teacherStatIcon1,
    identifier: "totalAssignment",
  },
  {
    description: "Total assignment registered today",
    icon: images?.teacherStatIcon2,
    identifier: "AssignmentRegisteredToday",
  },
  {
    description: "Total assignment registered in this month",
    icon: images?.teacherStatIcon3,
    identifier: "AssignmentRegisteredThisMonth",
  },
  {
    description: "Total assignment registered in this year",
    icon: images?.teacherStatIcon4,
    identifier: "AssignmentRegisteredThisYear",
  },
];
export const totalStats = [
  {
    description: "Total to-do registered",
    icon: images?.teacherStatIcon1,
    identifier: "totalTodo",
  },
  {
    description: "Total to-do registered today",
    icon: images?.teacherStatIcon2,
    identifier: "TodoRegisteredToday",
  },
  {
    description: "Total to-do registered in this month",
    icon: images?.teacherStatIcon3,
    identifier: "TodoRegisteredThisMonth",
  },
  {
    description: "Total to-do registered in this year",
    icon: images?.teacherStatIcon4,
    identifier: "TodoRegisteredThisYear",
  },
];

export const DASHBOARD_CONTENT = [
  {
    label: "Students",
    hasTable: false,
    statData: dashboardStats,
    statKey: "studentStat",
    pieChartKey: "studentChart",
  },
  {
    label: "Classes",
    hasTable: true,
    tableLabel: "Classes List",
    statData: classStats,
    statKey: "classStat",
    tableDataKey: "classList",
    pieChartKey: "classChart",
    tableContent: {
      isClassList: true,
      identifier: "classes",
      columns: CLASS_TABLE_COLUMNS,
      deletApi: APIS.DELETE_CLASS,
      actionMenu: CLASS_TABLE_ACTION_MENU,
      tableTitle: "classes",
    },
  },
  {
    label: "Teachers",
    hasTable: true,
    tableLabel: "Teachers List",
    statData: teacherStats,
    statKey: "teacherStat",
    pieChartKey: "teacherChart",
    tableDataKey: "teacherList",
    tableContent: {
      identifier: "teacher",
      route: "/teacher-management",
      columns: TEACHER_TABLE_COLUMNS,
      pageTitle: "Teacher List",
      pageButtonLabel: "Add Teacher",
      deletApi: APIS.DELETE_TEACHER,
      listApi: APIS.TEACHER_LIST,
      statusChanged: APIS.TEACHER_STATUS_CHANGE,
      actionMenu: TEACHER_TABLE_ACTION_MENU,
      addLink: "/teacher-add",
      editLink: "/teacher-edit",
      viewLink: "/teacher-view",
      tableTitle: "Teachers",
    },
  },
  {
    label: "Assignment",
    hasTable: false,
    statData: assignmentStats,
    statKey: "assignmentStat",
    pieChartKey: "assignmentChart",
  },
  {
    label: "Total To Do",
    hasTable: false,
    statData: totalStats,
    statKey: "todoStat",
    pieChartKey: "todoChart",
  },
];
