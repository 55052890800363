import React from "react";
import { useDispatch } from "react-redux";
import { images } from "../../constants/ImageImports";
import { hideModal } from "../../redux/slices/modalSlice";

export const Delete = ({ actionHandler }) => {
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(hideModal());
  };

  const deleteHandler = () => {
    actionHandler();
    dispatch(hideModal());
  };
  return (
    <div className="mdl_inr logout_mdl img_overlay">
      <div className="cross_icon" onClick={closeModal}>
        <img src={images?.modelCross} alt="img" className="prft_img" />
      </div>
      <div className="mdl_ctnt">
        <h5>Are you sure you want to Delete?</h5>
        <div className="btn-group mt-30">
          <button className="btn_reset pink_btn" onClick={deleteHandler}>
            Delete
          </button>
          <button className="btn_reset blue_btn" onClick={closeModal}>
            Cancel{" "}
          </button>
        </div>
      </div>
    </div>
  );
};
