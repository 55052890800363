import React, { useEffect, useState } from "react";
import { ViewProfile } from "../../../components/common/viewProfile/ViewProfile";
import { Tabbing } from "../../../components/common/tabbing/Tabbing";
import { APIS } from "../../../api/api.constant";
import { postApi } from "../../../api/api.client";
import { useParams } from "react-router";
import { toggleLoader } from "../../../redux/slices/loaderSlice";
import { useDispatch } from "react-redux";
import {
  PresentTask,
  CompletedTask,
  ShowTab,
  UpcomingTask,
} from "../../../features/todoListTabContent";
import { images } from "../../../constants/ImageImports";

const STDENT_VIEW_FIEDLS = [
  { title: "Mobile Number", identifier: "phone" },
  { title: "Address", identifier: "address" },
  { title: "Class Name", identifier: "className", hasUpdate: true },
  { title: "Course Name", identifier: "courseName" },
  { title: "Pronoun", identifier: "gender" },
  { title: "Age", identifier: "age" },
];

const createStudentdata = (studentData) => {
  let temp = {
    name: studentData?.name,
    email: studentData?.email,
    phone: studentData?.phone,
    address: studentData?.address,
    className: studentData?.student?.class_id,
    courseName: studentData?.student?.course_name,
    gender: studentData?.gender,
    age: studentData?.age,
    photo: studentData?.photo,
    background: studentData?.background,
    id: studentData?._id,
  };
  return temp;
};

export const StudentView = () => {
  const dispatch = useDispatch();
  const { id } = useParams("id");
  const [count, setCount] = useState({
    all: "",
    upcoming: "",
    present: "",
    complete: "",
  });
  const [profileData, setProfileData] = useState({});

  useEffect(() => {
    dispatch(toggleLoader({ loader: true }));
    postApi(APIS.STUDENT_BY_ID, { id: id })
      .then((res) => {
        setProfileData(createStudentdata(res?.payload?.data?.[0]));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(toggleLoader({ loader: false }));
      });
  }, []);

  useEffect(() => {
    dispatch(toggleLoader({ loader: true }));
    const apiRequests = [
      postApi(APIS.TO_DO_LIST, {
        todo_type: "all",
        page: 1,
        pageSize: 11,
        student_id: id,
      }),
      postApi(APIS.TO_DO_LIST, {
        todo_type: "upcoming",
        page: 1,
        pageSize: 11,
        student_id: id,
      }),
      postApi(APIS.TO_DO_LIST, {
        todo_type: "current",
        page: 1,
        pageSize: 11,
        student_id: id,
      }),
      postApi(APIS.TO_DO_LIST, {
        todo_type: "past",
        page: 1,
        pageSize: 11,
        student_id: id,
      }),
    ];
    Promise.all(apiRequests)
      .then(([allCount, upcommingCount, currentCount, pastCount]) => {
        setCount({
          all: allCount?.payload?.data?.length,
          upcoming: upcommingCount?.payload?.data?.length,
          present: currentCount?.payload?.data?.length,
          complete: pastCount?.payload?.data?.length,
        });
      })
      .catch(() => {})
      .finally(() => {
        dispatch(toggleLoader({ loader: false }));
      });
  }, []);

  const TodoListTabbing = [
    {
      title: "Show All",
      content: <ShowTab byId={{ idType: 1, id: id }} />,
      icon: images?.all,
      task: count?.all,
    },
    {
      title: "Upcoming",
      content: <UpcomingTask byId={{ idType: 1, id: id }} />,
      icon: images?.present,
      task: count?.upcoming,
    },
    {
      title: "Present To-do",
      content: <PresentTask byId={{ idType: 1, id: id }} />,
      icon: images?.upcoming,
      task: count?.present,
    },
    {
      title: "Past To-do List",
      content: <CompletedTask byId={{ idType: 1, id: id }} />,
      icon: images?.completed,
      task: count?.complete,
    },
  ];

  return (
    <div className="view_profile_page sec-padding">
      <div className="cstm_Ctnr">
        <ViewProfile
          isStudent={true}
          data={profileData}
          fields={STDENT_VIEW_FIEDLS}
          navigationRoute={"/student-edit"}
        />
        <div className="tab_btn_full mt-40">
          <Tabbing tabs={TodoListTabbing} />
        </div>
      </div>
    </div>
  );
};

export default StudentView;
