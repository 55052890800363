import React, { useEffect } from "react";
import "./table.css";
import { images } from "../../../constants/ImageImports";
import { BlockButton } from "./BlockButton";
import ActionButtons from "../actionButtons";
import { isValid, getFormattedDate } from "../../../utils";
import { Pagination, Stack } from "@mui/material";
import NoDataFound from "../noDataFound";

const UserInfo = ({ obj }) => {
  return (
    <div className="pr_icn_wpr">
      <span className="pr_icn">
        <img
          className="circle prft_img"
          crossOrigin="*"
          src={
            obj?.photo !== null && obj?.photo !== undefined && obj?.photo !== ""
              ? obj?.photo
              : images?.profile_cover
          }
          alt="student-image"
        />
      </span>
      {obj?.name}
    </div>
  );
};

export const Table = ({
  tableContent,
  tableData,
  onActionHandler,
  filter,
  setFilter,
  pagination,
  isFilter = true,
  isPagination = true,
}) => {
  const CustomPagination = () => {
    return (
      <Stack spacing={2}>
        <Pagination
          count={pagination?.totalPages}
          shape="rounded"
          page={filter?.page}
          onChange={(value, currentPage) => {
            setFilter({ ...filter, page: currentPage });
          }}
        />
      </Stack>
    );
  };

  return (
    <div className="table_wpr">
      <div className="table_hdr">
        <h4>
          {tableContent.tableTitle && tableData?.length > 0
            ? `Most recent 10 ${tableContent.tableTitle}`
            : ""}
        </h4>
        <div
          className="search_wpr"
          style={{ visibility: isFilter ? "visible" : "hidden" }}
        >
          <input
            type="text"
            placeholder="Search"
            onChange={(event) => {
              setFilter({ ...filter, search: event.target.value });
            }}
          />
          <span>
            <img src={images?.searchIcon} alt="img" />
          </span>
        </div>
      </div>
      <div className="table_responsive">
        <table className="table">
          <thead>
            <tr>
              {tableContent?.columns?.map((obj, i, arr) => {
                return <th key={`${obj.label}-${i}`}>{obj?.label}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {tableData?.map((obj, i, arr) => {
              return (
                <tr key={`${obj.name}-${i}`}>
                  {tableContent?.isClassList && <td>{i + 1}.</td>}
                  {tableContent?.isClassList && (
                    <td>{isValid(obj?.class_title)}</td>
                  )}
                  {tableContent?.isClassList && (
                    <td>{getFormattedDate(obj?.publishedDate)}</td>
                  )}
                  {tableContent?.isClassList && <td>{obj?.studentCount}</td>}
                  {!tableContent?.isClassList && (
                    <td style={{ textTransform: "capitalize" }}>
                      <UserInfo obj={obj} />
                    </td>
                  )}
                  {!tableContent?.isClassList && <td>{obj?.email}</td>}
                  {!tableContent?.isClassList && <td>{isValid(obj?.phone)}</td>}
                  {!tableContent?.isClassList && <td>{isValid(obj?.age)}</td>}
                  {!tableContent?.isClassList && (
                    <td>
                      <BlockButton
                        data={obj}
                        onActionHandler={onActionHandler}
                        tableContent={tableContent}
                      />
                    </td>
                  )}
                  <td>
                    <ActionButtons
                      actionMenu={tableContent?.actionMenu}
                      onActionHandler={onActionHandler}
                      data={obj}
                      tableContent={tableContent}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {tableData?.length === 0 && <NoDataFound tableData={tableData} />}
        {isPagination && tableData?.length > 0 && <CustomPagination />}
      </div>
    </div>
  );
};
