import React, { useEffect } from "react";
import { WithTodoListHoc } from "../../hoc";

const PresentTask = (props) => {
  useEffect(() => {
    props.setApiType("current");
  });
  return <></>;
};
export default WithTodoListHoc(PresentTask);
