import React from "react";
import { images } from "../../../constants/ImageImports";
import { getToast } from "../../../utils";
import { TOAST_MESSAGE } from "../../../constants/constants";

const ALLOW_IMAGES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/svg+xml",
  "image/svg",
];

const STATIC_IMAGE_CHECK = [
  "/static/media/bg_cover.ea7b18e0704561775829.jpg",
  "/static/media/profile_cover.dfd2b8424f6509d45eca.jpg",
];

const ImageUploader = ({
  noMargin = false,
  image,
  name,
  className,
  setFieldValue,
  fieldName,
  setState,
  state,
}) => {
  const imageHandler = (val) => {
    const getImage = val.target.files[0];
    const MBSzie = (getImage?.size / (1024 * 1024)).toFixed(2);

    if (!ALLOW_IMAGES.includes(getImage?.type)) {
      getToast("error", TOAST_MESSAGE.WRONG_FILE);
    } else {
      if (Number(MBSzie) >= 5) {
        getToast("error", TOAST_MESSAGE.SIZE_ERROR);
      } else {
        const imgUrl = URL.createObjectURL(getImage);
        setFieldValue([fieldName], getImage);
        setState(imgUrl);
      }
    }
  };

  return (
    <div
      className={className ? `${className} prf_upload` : "prf_upload"}
      style={{
        borderRadius: !className && "50%",
        marginTop: !noMargin ? "-41px" : "0px",
      }}
    >
      {className ? (
        <img
          className={image ? "preview_img" : "preview_img"}
          style={{
            objectFit: STATIC_IMAGE_CHECK.includes(image) ? "contain" : "cover",
          }}
          src={state || image}
          alt="img"
          crossOrigin="anonymous"
        />
      ) : (
        <img
          className={"preview_img"}
          style={{ borderRadius: "50%" }}
          src={state || image}
          alt="img"
          crossOrigin="anonymous"
        />
      )}

      {!className && <img src={images?.camera} alt="img" />}
      <input type="file" onChange={imageHandler} accept="image/*" name={name} />
    </div>
  );
};

export default ImageUploader;
