import React from "react";

const FieldError = ({ errors, touched, fieldObj }) => {
  return (
    <>
      {errors?.[fieldObj?.name] && touched?.[fieldObj?.name] ? (
        <div
          style={{
            color: "red",
            fontWeight: 600,
            fontFamily: "13px",
            fontStyle: "italic",
            paddingLeft: "10px",
          }}
        >
          {errors?.[fieldObj?.name]}
        </div>
      ) : (
        <div
          style={{
            color: "red",
            fontWeight: 600,
            fontFamily: "13px",
            visibility: "hidden",
            paddingLeft: "10px",
          }}
        >
          Erro
        </div>
      )}
    </>
  );
};
export default FieldError;
