import React, { useEffect, useState } from "react";
import "./viewProfile.css";
import { images } from "../../../constants/ImageImports";
import { Link } from "react-router-dom";
import { isValid } from "../../../utils";
import { APIS } from "../../../api/api.constant";
import { postApi } from "../../../api/api.client";
import { useDispatch } from "react-redux";
import { toggleLoader } from "../../../redux/slices/loaderSlice";

export const ViewProfile = ({
  data,
  fields,
  navigationRoute,
  isStudent = false,
}) => {
  const [classList, setClassList] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(toggleLoader({ loader: true }));
    postApi(APIS.CLASS_LIST, {})
      .then((res) => {
        setClassList(res?.payload?.data?.result);
      })
      .catch(() => {})
      .finally(() => {
        dispatch(toggleLoader({ loader: false }));
      });
  }, []);

  const getClassName = (data, classList) => {
    const classValue = classList.find(
      (obj, i, arr) => obj?._id === data?.className
    );
    return `${classValue?.class_title}`;
  };
  return (
    <section className="viewprofile_sec">
      <div className="vp_cover_img">
        <img
          crossorigin="anonymous"
          className="prft_img"
          src={
            data?.background !== null &&
            data?.background !== "" &&
            data?.background !== undefined
              ? data?.background
              : images?.bgCover
          }
          alt="background image"
        />
      </div>
      <div className="vp_person_bx">
        <div className="vp_person_img">
          <img
            crossorigin="anonymous"
            className="prft_img"
            src={
              data?.photo !== null &&
              data?.photo !== undefined &&
              data?.photo !== ""
                ? data?.photo
                : images?.profile_cover
            }
            alt="profile_image"
          />
        </div>
        <div className="vp_person_wpr">
          <div className="vp_person_info">
            <h4>{isValid(data?.name)}</h4>
            <p>{isValid(data?.email)}</p>
          </div>
          <div className="btn_group">
            <Link
              to={`${navigationRoute}/${data?.id}`}
              className="btn_reset pink_btn sm"
            >
              Edit
            </Link>
          </div>
        </div>
      </div>
      <ul
        className="vp_basic_info"
        style={{ padding: !isStudent && "50px 140px" }}
      >
        {fields?.map((obj, i, arr) => {
          return (
            <li
              key={`${obj?.title}-${i}`}
              style={{
                width: isStudent && "150px",
                wordWrap: "break-word",
              }}
            >
              <b>{obj?.title}</b>
              <p>
                {obj?.hasUpdate
                  ? getClassName(data, classList)
                  : isValid(data?.[obj?.identifier])}
              </p>
            </li>
          );
        })}
      </ul>
    </section>
  );
};
