import React from "react";

const ActionButtons = ({ actionMenu, onActionHandler, data, tableContent }) => {
  return (
    <ul className="action_btn_wpr">
      {actionMenu?.map((obj, i, arr) => {
        return (
          <li key={`${obj?.key}-${i}`}>
            <img
              className="prft_img circle transition"
              src={obj?.icon}
              alt={obj?.key}
              onClick={(e) => onActionHandler(e, obj?.key, data, tableContent)}
            />
          </li>
        );
      })}
    </ul>
  );
};
export default ActionButtons;
