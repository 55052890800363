import React from "react";

export const StatCard = ({ index, data, value }) => {
  const { icon, description, identifier } = data;

  return (
    <div className="stat_card">
      <div className="stat_icon">
        <img src={icon} alt="stat_Icon" />
      </div>
      <div className="stat_ctnt">
        <div className="stat_no">{value?.[identifier] || 0}</div>
        <span>{description}</span>
      </div>
    </div>
  );
};
