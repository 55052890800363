import React, { useEffect, useState } from "react";
import { postApi } from "../../api/api.client";
import { APIS } from "../../api/api.constant";
import { ADMIN_UPDATE_FORM } from "../../constants/formik";
import { useFormik } from "formik";
import { MixComponents, ImageUploader } from "../../components/common";
import { useDispatch, useSelector } from "react-redux";
import { toggleLoader } from "../../redux/slices/loaderSlice";
import { images } from "../../constants/ImageImports";
import { getToast } from "../../utils";
import { TOAST_MESSAGE } from "../../constants/constants";
import { COMMON_PHONE_REGEX } from "../../constants/constants";
import { setUser } from "../../redux/slices/authSlice";

const TAB_TYPES = {
  INFO: "info",
  EDIT: "edit",
};

const TAB = [{ type: TAB_TYPES.INFO }, { type: TAB_TYPES.EDIT }];

const getOtherTabType = (currentTabType) => {
  if (currentTabType === TAB_TYPES.INFO) {
    return TAB_TYPES.EDIT;
  } else if (currentTabType === TAB_TYPES.EDIT) {
    return TAB_TYPES.INFO;
  } else {
    return null;
  }
};
const USER_INFO = [
  {
    label: "Phone",
    key: "phone",
    icon: images?.profilePhoneIcon,
  },
  {
    label: "Email",
    key: "email",
    icon: images?.profileMailIcon,
  },
];

const ProfileData = ({ userData }) => {
  return (
    <div className="prf_details prf_tab">
      <div className="prf_img">
        <img
          crossOrigin="*"
          src={
            userData?.photo !== null &&
            userData?.photo !== undefined &&
            userData?.photo !== ""
              ? userData?.photo
              : images.profile_cover
          }
          alt="profile_image"
        />
      </div>
      <div className="prf_description">
        <h5 style={{ textTransform: "capitalize" }}>{userData?.name}</h5>
        <div className="prf_fields">
          {USER_INFO.map((obj, i, arr) => {
            return (
              <div className="prf_field_Item" key={`${obj?.icon}-${i}`}>
                <div className="prf_label">{obj?.label}</div>
                <div className="prf_text" style={{ display: "flex" }}>
                  <span className="prf_Icon">
                    <img
                      src={obj?.icon}
                      alt={obj?.label}
                      style={{ height: obj?.size && obj?.size }}
                    />
                  </span>
                  {userData?.[obj?.key] ? userData?.[obj?.key] : "N/A"}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const ProfileEditData = ({
  image,
  values,
  errors,
  setFieldValue,
  touched,
  setTouched,
  setImage,
}) => {
  const [profileImage, setProfileImage] = useState("");
  return (
    <div className="prf_details edit_details">
      <ImageUploader
        noMargin={true}
        fieldName={"photo"}
        setState={setProfileImage}
        state={profileImage}
        setFieldValue={setFieldValue}
        image={
          values?.photo !== null &&
          values?.photo !== undefined &&
          values?.photo !== ""
            ? values?.photo
            : images?.profile_cover
        }
        name={"file"}
      />
      <form>
        <div className="prf_description">
          <div className="prf_fields edit_fields">
            {ADMIN_UPDATE_FORM.FIELDS.map((fieldObj, i, arr) => {
              return (
                <div className="prf_field_Item" key={`${fieldObj?.name}-${i}`}>
                  <div className="prf_label">{fieldObj?.label}</div>
                  <div className="prf_text">
                    <MixComponents
                      isEdit={true}
                      fieldObj={fieldObj}
                      values={values}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      touched={touched}
                      setTouched={setTouched}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </form>
    </div>
  );
};

export const Profile = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(TAB?.[0]?.type);
  const [userData, setUserData] = useState({});
  const currentUser = useSelector((state) => state?.authSlice);
  const [image, setImage] = useState(null);

  useEffect(() => {
    dispatch(toggleLoader({ loader: true }));
    postApi(APIS.GET_ADMIN_DETAILS, {})
      .then((res) => {
        if (res?.payload?.data?.photo !== null) {
          setImage(userData?.photo);
        }
        setUserData(res?.payload?.data);
      })
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => {
        dispatch(toggleLoader({ loader: false }));
      });
  }, [activeTab]);

  const onSubmit = (payload) => {
    if (values?.phone?.charAt(0) === "0" && values?.phone?.charAt(9) === "0") {
      return getToast("error", TOAST_MESSAGE.PHONE_VALIDATION);
    }
    if (values?.phone?.charAt(0) === "0") {
      return getToast("error", TOAST_MESSAGE.PHONE_VALIDATION);
    }
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("email", values.email);
    formData.append("phone", values.phone);
    // formData.append("address", payload.address);
    values?.photo && formData.append("profile_image", values.photo);
    dispatch(toggleLoader({ loader: true }));
    postApi(APIS.UPDATE_ADMIN, Object.fromEntries(formData), true)
      .then((res) => {
        getToast("success", TOAST_MESSAGE.UPDATE_USER);
        setActiveTab(getOtherTabType(activeTab));
        dispatch(
          setUser({
            ...currentUser,
            name: payload?.name,
            email: payload?.email,
            isAdmin: true,
          })
        );
      })
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => {
        dispatch(toggleLoader({ loader: false }));
      });
  };

  const formik = useFormik({
    initialValues: userData || ADMIN_UPDATE_FORM?.INITIAL_VALUES,
    onSubmit: onSubmit,
    validationSchema: ADMIN_UPDATE_FORM?.SCHEMA,
    enableReinitialize: true,
  });

  const { handleSubmit, errors, values, setFieldValue, touched, setTouched } =
    formik;

  return (
    <form onSubmit={handleSubmit}>
      <div className="tab_wpr">
        <div className="tab_hdg_wpr">
          <h5 className="tab_hdg">
            {activeTab === TAB_TYPES.EDIT ? "Edit Profile" : "Profile"}
          </h5>
          <div className="btn_group d-flex gap-10px">
            {activeTab === TAB_TYPES.EDIT && (
              <button
                type="button"
                className="theme_btn btn_reset"
                onClick={() => setActiveTab(getOtherTabType(activeTab))}
                style={{ marginRight: "20px" }}
              >
                Back
              </button>
            )}
            <button
              className="pink_btn btn_reset"
              type={activeTab === TAB_TYPES.EDIT ? "submit" : "button"}
              onClick={(event) => {
                if (activeTab === TAB_TYPES.INFO) {
                  event.stopPropagation();
                  event.preventDefault();
                  setActiveTab(getOtherTabType(activeTab));
                }
              }}
            >
              {activeTab === TAB_TYPES.EDIT ? "Save" : "Edit Profile"}
            </button>
          </div>
        </div>
        {activeTab === TAB_TYPES.EDIT ? (
          <ProfileEditData
            image={image}
            values={values}
            errors={errors}
            setFieldValue={setFieldValue}
            touched={touched}
            setTouched={setTouched}
            setImage={setImage}
          />
        ) : (
          <ProfileData userData={userData} image={image} />
        )}
      </div>
    </form>
  );
};
