import React from "react";
import { images } from "../../../constants/ImageImports";
import { CustomPopover } from "../customPopover/CustomPopover";

const getValidDate = (dateString) => {
  if (dateString !== null && dateString !== undefined && dateString !== "") {
    const dateObject = new Date(dateString);
    const day = ("0" + dateObject.getDate()).slice(-2);
    const month = ("0" + (dateObject.getMonth() + 1)).slice(-2);
    const year = dateObject.getFullYear().toString().slice(-2);
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }
  return "N/A";
};

export const TaskStepper = ({ itemData }) => {

  return (
    <ul className="stepper_list">
      <li
        className={`${itemData?.is_submited ||
          itemData?.need_rework ||
          itemData?.approved_by_teacher
          ? "stepper_item submitted"
          : "stepper_item"
          }`}
      >
        <div className="step_name">Submitted</div>
        <div className="step_mid">
          {itemData?.is_submited ||
            itemData?.need_rework ||
            itemData?.approved_by_teacher ? (
            <div className="color_dot"></div>
          ) : (
            <img src={images?.stepperCheck} alt="check" />
          )}
          <span></span>
        </div>
        <div className="step_date">
          {getValidDate(itemData?.submited_date)}
          {itemData?.submited_text && (
            <CustomPopover
              icon={images?.infoIico}
              alt="infoIico"
              text={itemData?.submited_text}
            />
          )}
        </div>
      </li>
      {itemData?.is_rework && (
        <li
          className={`${itemData?.is_rework ? "stepper_item rework" : "stepper_item"
            }`}
        >
          <div className="step_name">Need Rework</div>
          <div className="step_mid">
            {itemData?.is_rework ? (
              <div className="color_dot"></div>
            ) : (
              <img src={images?.stepperCheck} alt="check" />
            )}
            <span></span>
          </div>
          <div className="step_date">
            {getValidDate(itemData?.rework_date)}
            {itemData?.rework_text && (
              <CustomPopover
                icon={images?.infoIico}
                alt="infoIico"
                text={itemData?.rework_text}
              />
            )}
          </div>
        </li>
      )}

      <li
        className={`${itemData?.is_approved ? "stepper_item approved" : "stepper_item"
          }`}
      >
        <div className="step_name">Approved by teacher</div>
        <div className="step_mid">
          {itemData?.is_approved ? (
            <div className="color_dot"></div>
          ) : (
            <img src={images?.stepperCheck} alt="check" />
          )}
          <span></span>
        </div>
        <div className="step_date">
          {getValidDate(itemData?.approved_date)}
          {itemData?.approved_text && (
            <CustomPopover
              icon={images?.infoIico}
              alt="infoIico"
              text={itemData?.approved_text}
            />
          )}
        </div>
      </li>
    </ul>
  );
};
