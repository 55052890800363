// export const BASE_URL = "http://3.111.233.200:8088"; //Development URL
// export const BASE_URL = "http://192.168.1.12:8088"; // Local network URL
// export const BASE_URL = "https://apiriseadmin.developmentrecords.com"; //Production URL
// export const MEDIA_URL = "http://3.111.233.200:8088";
// export const MEDIA_URL = "http://192.168.1.12:8088"; // Local network URL


export const BASE_URL = process.env.REACT_APP_RISE_ADMIN_DEV_BASE_URL
// process.env.NODE_ENV === "development"  
//   ? process.env.REACT_APP_RISE_ADMIN_DEV_BASE_URL
//   : process.env.REACT_APP_RISE_ADMIN_PRODUCTION_BASE_URL;


export const API_PREFIX = "/api/v1/admin";
export const PERSIST_STORAGE = "persist:root";

export const APIS = {
  LOGIN: "/login",
  STUDENT_LIST: "/view/student",
  TEACHER_LIST: "/view/teacher",
  DELETE_TEACHER: "/delete/teacher",
  DELETE_STUDENT: "/delete/student",
  TEACHER_STATUS_CHANGE: "/teacher/status/update",
  STUDENT_STATUS_CHANGE: "/student/status/update",
  TEACHER_BY_ID: "/teacher/details",
  STUDENT_BY_ID: "/student/profile",
  CLASS_LIST: "/class/list",
  CREATE_STUDENT: "/create/student",
  UPDATE_STUDENT: "/update/student/profile",
  CREATE_TEACHER: "/create/teacher",
  UPDATE_TEACHER: "/update/teacher",
  STUDENT_STAT_DASHBOARD: "/dashboard/registration/student",
  CLASS_STAT_DASHBOARD: "/dashboard/class/analysis",
  TEACHER_STAT_DASHBOARD: "/dashboard/teacher/analysis",
  ASSIGNEMENT_STAT_DASHBOARD: "/dashboard/assignment/analysis",
  TODO_STAT_DASHBOARD: "/dashboard/todo/analysis",
  CLASS_LIST_DASHBOARD: "/dashboard/class/list",
  TEACHER_LIST_DASHBOARD: "/dashboard/teacher/list",
  DELETE_CLASS: "/delete/class",
  UPDATE_CLASS: "/class/update",
  STUDENT_BAR_CHART: "/dashboard/student/barchat",
  CLASS_BAR_CHART: "/dashboard/class/barchat",
  TEACHER_BAR_CHART: "/dashboard/teacher/barchat",
  ASSIGNEMENT_BAR_CHART: "/dashboard/assignment/barchat",
  TO_DO_BAR_CHART: "/dashboard/todo/barchat",
  GET_ADMIN_DETAILS: "/profile",
  UPDATE_ADMIN: "/profile/update",
  CHANGE_PASSWORD: "/change/password",
  CONTACT_US: "/contact-us",
  FORGOT_PASSWORD: "/forgot/password",
  GET_STATIC_CONTENT: "/get/static-content",
  CREATE_STATIC_CONTENT: "/create/privacy-policy",
  TO_DO_LIST: "/todo/bycategory",
  RESET_PASSWORD: "/save/password",
  GET_ADMIN_INFO: "/contact-us",
  POST_ADMIN_INFO: "/createOrUpdatecontact",
};
