import React from "react";
import { useDispatch } from "react-redux";
import { images } from "../../constants/ImageImports";
import { hideModal } from "../../redux/slices/modalSlice";
import { SelectBox } from "../common/formInputs/SelectBox";

export const VoiceModal = () => {
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(hideModal());
  };
  return (
    <div className="mdl_inr voice_mdl">
      <div className="cross_icon" onClick={closeModal}>
        <img src={images?.modelCross} alt="img" className="prft_img" />
      </div>
      <div className="mdl_hdr img_hdr left">
        <img src={images?.voiceIcon} alt="img" />
        <h4>My voice</h4>
      </div>
      <div className="mdl_ctnt">
        <h5 className="fz-20 pink-color left mb-20 mt-30">Tone of Voice</h5>
        <ul className="tone_list mb-30">
          <li>
            <input type="radio" id="excited" name="tone" value="excited" />
            <label for="excited">
              <img src={images?.emoji1} alt="img" />
              Excited
            </label>
          </li>
          <li>
            <input type="radio" id="positive" name="tone" value="positive" />
            <label for="positive">
              <img src={images?.emoji2} alt="img" />
              Positive
            </label>
          </li>
          <li>
            <input type="radio" id="witty" name="tone" value="witty" />
            <label for="witty">
              <img src={images?.emoji3} alt="img" />
              Witty
            </label>
          </li>
          <li>
            <input type="radio" id="serious" name="tone" value="serious" />
            <label for="serious">
              <img src={images?.emoji4} alt="img" />
              Serious
            </label>
          </li>
          <li>
            <input type="radio" id="confident" name="tone" value="confident" />
            <label for="confident">
              <img src={images?.emoji4} alt="img" />
              Confident
            </label>
          </li>
          <li>
            <input
              type="radio"
              id="straightforward"
              name="tone"
              value="straightforward"
            />
            <label for="straightforward">
              <img src={images?.emoji4} alt="img" />
              Straightforward
            </label>
          </li>
          <li>
            <input type="radio" id="firm" name="tone" value="firm" />
            <label for="firm">
              <img src={images?.emoji4} alt="img" />
              Firm
            </label>
          </li>
          <li>
            <input
              type="radio"
              id="encouraging"
              name="tone"
              value="encouraging"
            />
            <label for="encouraging">
              <img src={images?.emoji4} alt="img" />
              Encouraging
            </label>
          </li>
        </ul>
        <div className="input_wpr bg-select select__bx">
          <h5 className="fz-20 left mb-10">Tone of Voice</h5>
          <select>
            <option disabled selected>
              Select Language
            </option>
            <option>Option 1</option>
            <option>Option 2</option>
            <option>Option 3</option>
            <option>Option 4</option>
          </select>
        </div>
      </div>
    </div>
  );
};
